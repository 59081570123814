<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
// import receiptroComponent from "@/components/tabReceiptRoComponent";
import DatePicker from "vue2-datepicker";
import { required } from "vuelidate/lib/validators";

// import { tableData } from "./dataAdvancedtable";
export default {
  page: {
    title: "เพิ่มข้อมูลใบเสนอราคา",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    // receiptroComponent,
    DatePicker,
  },
  data() {
    return {
      optionTypeDc: [
        {
          name: "บาท",
          type: "bath",
        },
        {
          name: "เปอรเซ็น",
          type: "per",
        },
      ],
      optionsRoWhtPer: [
        {
          name: "ไม่ระบุ",
          id: 0,
        },
        {
          name: "3%",
          id: 3,
        },
      ],
      wages: {
        name: "ไม่ระบุ",
      },
      form: false,
      loading: false,
      wageOptions: [],
      wageOptionsRo: [],
      wageItem: {
        wageId: "",
        selectWage: {},
        nameTh: "",
        nameEn: "",
        wageCode: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: {
          name: "บาท",
          type: "bath",
        },
      },
      // part: [],
      // partItem: {
      //   partId: "",
      //   selectPart: {},
      //   pricePerOne: 0,
      //   amount: 0,
      //   totalPrice: 0,
      //   dcPer: 0,
      //   dcPrice: 0,
      //   netPrice: 0,
      //   dcMethod: {
      //     name: "บาท",
      //     type: "bath",
      //   },
      // },
      rowParts: [],
      rowRoParts: [],
      rowRoWages: [],
      userBranchId: "",
      currentPage: 1,
      perPage: 10,
      totalpartNetPrices: "",
      totalpartPrices: "",
      totalpartDcPer: "",
      totalparttotalPrice: "",
      sumtotalDcPrice: "",
      saleType: "",
      totalwageNetPrices: "",
      totalwagePrices: "",
      totalwageDcPer: "",
      totalwagetotalPrice: "",
      sumtotalwageDcPrice: "",
      repairOrder: [],
      filter: {
        nameTh: "",
        wpCode: "",
      },
      totalPage: "",
      roType: "",
      totalRows: 1,
      totalRecord: 0,
      pageOptions: [10, 25, 50, 100],
      selectMode: "single",
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      optionTypeWp: [
        {
          id: 1,
          type: "W",
          nameTh: "ค่าแรง",
        },
        {
          id: 2,
          type: "P",
          nameTh: "ค่าอะไหล่",
        },
      ],
      fieldsWage: [
        {
          key: "index",
          sortable: true,
          label: "ลำดับ",
        },
        {
          key: "wageCode",
          sortable: true,
          label: "รหัสค่าแรง",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่อค่าแรง(ไทย)",
        },
        {
          key: "nameEn",
          sortable: true,
          label: "ชื่อค่าแรง(อังกฤษ)",
        },
        {
          key: "price",
          sortable: true,
          label: "ราคา",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      fieldsPart: [
        {
          key: "index",
          sortable: true,
          label: "ลำดับ",
        },
        {
          key: "partCode",
          sortable: true,
          label: "รหัสอะไหล่",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่ออะไหล่(ไทย)",
        },
        {
          key: "nameEn",
          sortable: true,
          label: "ชื่ออะไหล่(อังกฤษ)",
        },
        {
          key: "year",
          sortable: true,
          label: "ปี",
        },
        {
          key: "sellPrice",
          sortable: true,
          label: "ราคา",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      rowsWp: [],
      rowsP: [],
      rowsW: [],
      rowsWage: [],
      rowsPart: [],
      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",
      wpSelect: {
        totalPrice: 0,
        vatPer: appConfig.vat,
        vatPrice: 0,
        netPrice: 0,
        dcPrice: 0,
        dcPer: 0,
        grandTotalPrice: 0,
      },
      title: "เพิ่มข้อมูลใบเสนอราคา",
      items: [
        {
          text: "บริการ",
          active: true,
        },
        {
          text: "ใบเสนอราคา",
          active: false,
          href: "/quotations",
        },
        {
          text: "เพิ่มข้อมูลใบเสนอราคา",
          active: true,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: "",
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      arrTest: [],
      branchMaster: [],
      //   rowsRo: [],
      totalRecords: 0,
      isHidden: false,
      branch: "",
      type: "",
      roId: "",
      serviceRo: [],
      selectRO: "",
      optionBranch: [],
      filterCreate: {
        branch: "",
        vin: "",
        licensePlate: "",
      },

      qnId: this.$route.params.qnId,
      paymentType: "",
      receipt: [
        {
          id: 1,
          receiptName: "ออกใบกำกับภาษีปกติ",
        },
        {
          id: 2,
          receiptName: "ออกใบกำกับภาษีเคลม Warranty",
        },
      ],
      payment: [
        {
          id: 1,
          payName: "ชำระเงินสด",
          paymentType: "CASH",
        },
        {
          id: 2,
          payName: "เครดิต",
          paymentType: "CREDIT",
        },
        // {
        //   id: 3,
        //   payName: "โอนจ่าย",
        //   paymentType: "CREDIT",
        // },
      ],
      fieldsCreate: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "roCode",
          sortable: true,
          label: "รหัสใบซ่อม",
        },
        {
          key: "customerFullNameTh",
          sortable: true,
          label: "ชื่อ-สกุล",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ป้ายทะเบียน",
        },
        {
          key: "vehicleBrandEn",
          sortable: true,
          label: "ยี่ห้อ",
        },
        {
          key: "vehicleModelEn",
          sortable: true,
          label: "รุ่น",
        },
        {
          key: "roDate",
          sortable: true,
          label: "วันที่สั่งซ่อม",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ยอดรวม",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      userBranch: "",
      rows: [],
      overlayFlag: false,

      taxInvCode: "",
      licensePlate: "",
      customerNameTh: "",
      customerFamilyNameTh: "",
      branchName: "",
      cusName: "",
      payCash: "ชำระเงินสด",
      payCredit: "เครดิต",
      rowsCustomer: [],
      rowsVehicle: [],
      vehicle: "",
      qnDate: "",
      credit: "",
      dueDate: "",
      note: "",

      part: {
        partId: "",
        selectPart: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
        bath: "",
        per: "",
      },
      wage: {
        wageId: "",
        selectWage: {},
        nameTh: "",
        nameEn: "",
        wageCode: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
        bath: "",
        per: "",
      },
      liquid: {
        partId: "",
        selectLiquid: {},
        nameTh: "",
        nameEn: "",
        wageCode: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
        bath: "",
        per: "",
      },
      extra: {
        extraId: "",
        selectExtra: {},
        nameTh: "",
        nameEn: "",
        wageCode: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
        bath: "",
        per: "",
      },
      os: {
        osId: "",
        selectOs: {},
        nameTh: "",
        nameEn: "",
        wageCode: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
        bath: "",
        per: "",
      },
      //master os
      roOsTotalPrice: 0,
      roOsDcPer: 0,
      roOsDcPrice: 0,
      roOsNetPrice: 0,
      //master liquid
      roLiquidTotalPrice: 0,
      roLiquidDcPer: 0,
      roLiquidDcPrice: 0,
      roLiquidNetPrice: 0,
      //master extra
      roExtraTotalPrice: 0,
      roExtraDcPer: 0,
      roExtraDcPrice: 0,
      roExtraNetPrice: 0,
      //master wage
      totalNetPrice: "",
      roWageTotalPrice: 0,
      roWageDcPer: 0,
      roWageDcPrice: 0,
      roWagePrice: 0,
      //master part
      roPartTotalPrice: 0,
      roPartDcPer: 0,
      roPartDcPrice: 0,
      roPartNetPrice: 0,
      //master
      roTotalPrice: 0,
      roDcPer: 0,
      roDcPrice: 0,
      roNetPrice: 0,
      roVatPer: 7,
      roVatPrice: 0,
      roGrandTotalPrice: 0,
      roWhtPer: 0,
      roWhtPrice: 0,
      roPaymentAmount: 0,

      totalPrice: "",
      dcPer: "",
      dcPrice: "",
      netPrice: "",
      vatPer: "",
      vatPrice: "",
      grandTotalPrice: "",
      currentPageCreate: 1,
      perPageCreate: 10,
      submitform: false,
      selectedBranch: "",
      branchId: "",
      selectSV: "",
      qnDept: "",
      // qnDeptBp: "",
      qnTypeId: "",
      errormessage: "กรุณากรอกข้อมูลให้ครบ",
      rowsSv: [],
      rowRoLiquid: [],
      rowRoExtra: [],
      // rowRopart: [],
      rowRoOs: [],
      rowLiquid: [],
      rowOs: [],
      rowExtra: [],
      roReId: [],
      rowRoAll: [],
      roShow: [],
      beforWage: "",
      qnVatPrice: "",
      qnGrandTotalPrice: "",

      showBranchId: "",
      wageQnId: "",
      extraQnId: "",
      liquidQnId: "",
      qndId: "",

      srt: "",
      approved: "",
      noteDesc: "",
      qnType: [
        {
          id: 1,
          nameTh: "ADDPART",
        },
      ],
      rowsRo: [],
    };
  },
  validations: {
    selectedBranch: {
      required,
    },
    qnDate: {
      required,
    },
    qnTypeId: {
      required,
    },
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    // this.taxInvId = this.$route.params.taxInvId;
    if (this.$route.params.qnId != null) {
      this.getData();
      this.getDetail();
      // this.getDataWagePart();
    }

    this.getDataCustomer();
    this.getDataVehicle();
    // this.getDataRo();
    //     const date = new Date();
    // date.setDate(date.getDate() + 7);

    // var d = new Date(),
    //   month = "" + (d.getMonth() + 1),
    //   day = "" + d.getDate(),
    //   year = d.getFullYear();
    // if (month.length < 2) month = "0" + month;
    // if (day.length < 2) day = "0" + day;
    // this.date = year + "-" + month + "-" + day;

    // const date = new Date();
    // d.setDate(d.getDate() + 7);
    // this.getDataWagePart();
  },
  created() {},
  methods: {
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitQuotation();
      }
    },
    addformWage() {
      this.rowRoWages.push({
        wageId: "",
        selectWage: {},
        nameTh: "",
        nameEn: "",
        wageCode: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
      });
    },
    addformPart() {
      this.rowRoParts.push({
        partId: "",
        selectPart: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
      });
    },
    addformOs() {
      this.rowRoOs.push({
        osId: "",
        selectOs: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
      });
    },
    addformLiquid() {
      this.rowRoLiquid.push({
        partId: "",
        selectLiquid: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
      });
    },
    addformExtra() {
      this.rowRoExtra.push({
        extraId: "",
        selectExtra: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
      });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    customLabel({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },
    calDeleteWp(rowsWp) {
      this.wpSelect.totalPrice =
        parseFloat(this.totalPrice) - parseFloat(rowsWp.netPrice);
      this.wpSelect.dcPrice =
        (parseFloat(this.wpSelect.totalPrice) / 100) * this.dcPer;
      this.wpSelect.netPrice =
        parseFloat(this.wpSelect.totalPrice) -
        parseFloat(this.wpSelect.dcPrice);
      this.wpSelect.vatPrice =
        (parseFloat(this.wpSelect.netPrice) / 100) * this.vatPer;
      this.wpSelect.grandTotalPrice =
        parseFloat(this.wpSelect.netPrice) + this.wpSelect.vatPrice;
    },
    calUpdateWp() {
      let pricePerOne = 0;
      let netPrices = 0;
      // let dcPer = 0;
      // let totalPrice = 0;
      this.rowsWp.forEach((item) => {
        pricePerOne += parseFloat(item.netPrice);
        netPrices += parseFloat(item.totalPrice);
        // dcPer += parseFloat(item.dcPer);
        // totalPrice += parseFloat(item.totalPrice) * item.amount;
      });
      this.wpSelect.netPrice = netPrices.toFixed(2);
      this.wpSelect.totalPrice = pricePerOne.toFixed(2);
      this.wpSelect.vatPrice = (this.wpSelect.netPrice / 100) * this.vatPer;
      this.wpSelect.grandTotalPrice = netPrices + this.wpSelect.vatPrice;

      this.totalPrice = parseFloat(this.wpSelect.totalPrice);
      this.netPrice = parseFloat(this.totalPrice) - parseFloat(this.dcPrice);
      this.grandTotalPrice =
        parseFloat(this.netPrice) + parseFloat(this.vatPrice);
      // this.dcPer = (this.totalPrice / 100) * this.dcPer;
      this.dcPrice = (this.totalPrice / 100) * this.dcPer;
    },
    addWpInvoice: function(selected) {
      useNetw
        .post("api/tax-invoice/detail/store", {
          taxInvId: this.taxInvId,
          partId: selected.partId != null ? selected.partId : undefined,
          wageId: selected.wageId != null ? selected.wageId : undefined,
          type: this.roType.type,
          amount: 1,
          pricePerOne:
            selected.price != null ? selected.price : selected.sellPrice,
          totalPrice:
            selected.price != null ? selected.price : selected.sellPrice,
          dcPer: 0,
          dcPrice: 0,
          netPrice:
            selected.price != null ? selected.price : selected.sellPrice,
          vatPer: this.wpSelect.vatPer,
          vatPrice: this.wpSelect.vatPrice,
          grandTotalPrice:
            selected.price != null ? selected.price : selected.sellPrice,
          taxInvTotalPrice: this.wpSelect.totalPrice,
          taxInvDcPer: this.dcPer,
          taxInvDcPrice: this.wpSelect.dcPrice,
          taxInvNetPrice: this.wpSelect.netPrice,
          taxInvVatPer: this.vatPer,
          taxInvVatPrice: this.wpSelect.vatPrice,
          taxInvGrandTotalPrice: this.wpSelect.grandTotalPrice,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
          this.getDataWagePart();
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    handleSearch() {
      // this.getRo(this.branchIdRo);
      if (this.roType.type === "W") {
        this.getDataWage();
      } else {
        this.getDataPart();
      }
    },
    handleChangePage(page) {
      this.currentPage = page;
      if (this.roType.type === "W") {
        this.getDataWage();
      } else {
        this.getDataPart();
      }
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      if (this.roType.type === "W") {
        this.getDataWage();
      } else {
        this.getDataPart();
      }
    },

    getDataWagePart: function(taxInvId) {
      this.overlayFlag = true;
      useNetw
        .get("api/tax-invoice/detail", {
          params: {
            taxInvId: this.taxInvId != null ? this.taxInvId : taxInvId,
          },
        })
        .then((response) => {
          this.rowsWp = response.data.data;
          this.rowsWp.forEach((element, index) => {
            if (element.type === "W") {
              this.rowsWp[index].selectWage = {
                wageId: element.wageId,
                nameTh: element.wageNameTh,
              };
            } else if (element.type === "P") {
              this.rowsWp[index].selectWage = {
                partId: element.partId,
                nameTh: element.partNameTh,
              };
            }
            //   else if (element.type === "L") {
            //   this.rowsWp[index].selectWage = {
            //     partId: element.partId,
            //     nameTh: element.partNameTh,
            //   };
            // } else if (element.type === "OS") {
            //   this.rowsWp[index].selectWage = {
            //     partId: element.partId,
            //     nameTh: element.partNameTh,
            //   };
            // } else if (element.type === "OTH") {
            //   this.rowsWp[index].selectWage = {
            //     partId: element.partId,
            //     nameTh: element.partNameTh,
            //   };
            // }
            // this.totalPricewage(this.rowsWp);
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataWp(type) {
      if (type.type === "W") {
        this.getDataWage();
      } else {
        this.getDataPart();
      }
    },
    getDataWage() {
      this.loading = true;
      useNetw
        .get("api/wage", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]": this.userBranchId,
            nameTh: this.filter.nameTh,
            wageCode: this.filter.wpCode,
          },
        })
        .then((response) => {
          this.rowsWage = response.data.data;
          this.totalPage = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataPart() {
      this.loading = true;
      useNetw
        .get("api/part", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]": this.userBranchId,
            nameTh: this.filter.nameTh,
            partCode: this.filter.wpCode,
          },
        })
        .then((response) => {
          this.rowsPart = response.data.data;
          this.totalPage = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    // calTotalTax(){

    // },

    totalPricewage(wageItem) {
      // if (this.wageItem.dcMethod.type === "per") {
      wageItem.totalPrice = parseFloat(wageItem.pricePerOne) * wageItem.amount;
      wageItem.totalPrice = wageItem.totalPrice.toFixed(2);
      wageItem.dcPrice =
        (parseFloat(wageItem.totalPrice).toFixed(2) / 100) * wageItem.dcPer;
      wageItem.netPrice =
        parseFloat(wageItem.totalPrice).toFixed(2) - wageItem.dcPrice;
      wageItem.grandTotalPrice =
        parseFloat(wageItem.netPrice).toFixed(2) - wageItem.vatPrice;
      // wageItem.vatPer =
      // parseFloat(wageItem.netPrice).toFixed(2) - wageItem.vatPrice;
      wageItem.vatPrice = parseFloat(wageItem.netPrice / 100) * this.vatPer;
      this.calUpdateWp();

      // this.calUpdateWp(wageItem);
      // this.wpSelect.vatPrice =
      // (parseFloat(this.wpSelect.netPrice) / 100) * this.vatPer;
      // let pricePerOne = 0;
      // let netPrices = 0;
      // let dcPer = 0;
      // let totalPrice = 0;
      // let sum = 0;
      // this.rowsWp.forEach((item) => {
      //   pricePerOne += parseFloat(item.pricePerOne) * item.amount;
      //   netPrices += parseFloat(item.netPrice);
      //   dcPer += parseFloat(item.dcPer);
      //   totalPrice += parseFloat(item.totalPrice) * item.amount;
      //   sum += (parseFloat(item.totalPrice) / 100) * item.dcPer;
      // });
      // this.totalwageNetPrices = netPrices.toFixed(2);
      // this.totalwagePrices = pricePerOne.toFixed(2);
      // this.totalwageDcPer = dcPer.toFixed(2);
      // this.totalwagetotalPrice = totalPrice.toFixed(2);
      // this.sumtotalwageDcPrice = sum.toFixed(2);
      // } else if (this.wageItem.dcMethod.type === "bath") {
      //   wageItem.totalPrice =
      //     parseFloat(wageItem.pricePerOne).toFixed(2) * wageItem.amount;
      //   wageItem.totalPrice = wageItem.totalPrice.toFixed(2);
      //   wageItem.dcPrice = wageItem.dcPer;
      //   wageItem.netPrice =
      //     parseFloat(wageItem.totalPrice).toFixed(2) - wageItem.dcPrice;
      //   let pricePerOne = 0;
      //   let netPrices = 0;
      //   let dcPer = 0;
      //   let totalPrice = 0;
      //   let sum = 0;
      //   this.rowRoWages.forEach(function(item) {
      //     pricePerOne += parseFloat(item.pricePerOne) * item.amount;
      //     netPrices += parseFloat(item.netPrice);
      //     dcPer += parseFloat(item.dcPer);
      //     totalPrice += parseFloat(item.totalPrice) * item.amount;
      //     sum += parseFloat(item.dcPer);
      //   });
      //   this.totalwageNetPrices = netPrices.toFixed(2);
      //   this.totalwagePrices = pricePerOne.toFixed(2);
      //   this.totalwageDcPer = sum.toFixed(2);
      //   this.totalwagetotalPrice = totalPrice.toFixed(2);
      //   this.sumtotalwageDcPrice = dcPer.toFixed(2);
      // }
    },
    calWpPrice(rowRo) {
      this.wpSelect.totalPrice =
        parseFloat(rowRo.price != null ? rowRo.price : rowRo.sellPrice) * 1;
      this.wpSelect.netPrice =
        parseFloat(rowRo.price != null ? rowRo.price : rowRo.sellPrice) * 1;
      // this.wpSelect.vatPrice =
      //   ((parseFloat(rowRo.price != null ? rowRo.price : rowRo.sellPrice) * 1) /
      //     100) *
      //   parseFloat(this.wpSelect.vatPer);

      this.wpSelect.totalPrice =
        parseFloat(this.totalPrice) +
        parseFloat(rowRo.price != null ? rowRo.price : rowRo.sellPrice);
      this.wpSelect.dcPrice =
        (parseFloat(this.wpSelect.totalPrice) / 100) * this.dcPer;
      this.wpSelect.netPrice =
        parseFloat(this.wpSelect.totalPrice) -
        parseFloat(this.wpSelect.dcPrice);
      this.wpSelect.vatPrice =
        (parseFloat(this.wpSelect.netPrice) / 100) * this.vatPer;
      this.wpSelect.grandTotalPrice =
        parseFloat(this.wpSelect.netPrice) + this.wpSelect.vatPrice;
    },
    onRowSelected(rowRo) {
      this.selected = rowRo[0];
      this.calWpPrice(rowRo[0]);
      this.addWpInvoice(rowRo[0]);
      this.$refs["modalSearch"].hide();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
    },
    getData: function(qnId) {
      this.overlayFlag = true;
      useNetw
        .get("api/quotation/show", {
          params: {
            qnId: this.qnId != null ? this.qnId : qnId,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.taxInvCode = response.data.data.taxInvCode;
          this.roCode = response.data.data.roCode;
          this.qnDate = response.data.data.qnDate;
          this.dueDate = response.data.data.dueDate;
          this.totalPrice = response.data.data.totalPrice;
          this.dcPer = response.data.data.dcPer;
          this.dcPrice = response.data.data.dcPrice;
          this.netPrice = response.data.data.netPrice;
          this.vatPer = response.data.data.vatPer;
          this.vatPrice = response.data.data.vatPrice;
          this.grandTotalPrice = response.data.data.grandTotalPrice;
          this.showBranchId = response.data.data.branchId;
          this.selectSV = {
            svId: response.data.data.svId,
            svCode: response.data.data.svCode,
          };
          this.selectRO = {
            roId: response.data.data.roId,
            roCode: response.data.data.roCode,
          };
          this.vehicle = {
            licensePlate: response.data.data.licensePlate,
            vehicleId: response.data.data.vehicleId,
          };
          this.cusName = {
            cusId: response.data.data.cusId,
            nameTh:
              response.data.data.customerNameTh != null
                ? response.data.data.customerNameTh
                : "",
            familyNameTh:
              response.data.data.customerFamilyNameTh != null
                ? response.data.data.customerFamilyNameTh
                : "",
          };
          this.selectedBranch = {
            nameTh: response.data.data.branchName,
            branchId: response.data.data.branchId,
          };
          this.qnDept = response.data.data.qnDept;
          this.note = response.data.data.note;
          this.qnTypeId = { nameTh: response.data.data.qnTypeId };
          // this.grandTotalPrice = response.data.data.grandTotalPrice;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    updateTaxInvoice: function() {
      this.overlayFlag = true;
      if (this.paymentType.paymentType === "CASH") {
        this.credit = null;
        this.dueDate = null;
      }
      useNetw
        .put("api/tax-invoice/update", {
          taxInvId: this.taxInvId,
          cusId: this.cusName.cusId,
          vehicleId: this.vehicle.vehicleId,
          taxInvCode: this.taxInvCode,
          qnDate: this.qnDate,
          credit: this.credit != null ? this.credit : undefined,
          dueDate: this.dueDate != null ? this.dueDate : undefined,
          note: this.note,
          totalPrice: this.totalPrice,
          dcPer: this.dcPer,
          dcPrice: this.dcPrice,
          netPrice: this.netPrice,
          vatPer: this.vatPer,
          vatPrice: this.vatPrice,
          grandTotalPrice: this.grandTotalPrice,
          paymentType:
            this.paymentType.paymentType != null
              ? this.paymentType.paymentType
              : this.paymentType.paymentType,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataCustomer: function() {
      this.loading = true;
      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            "branchId[]": this.userBranchId,
            nameTh: this.cusName.nameTh,
          },
        })
        .then((response) => {
          this.rowsCustomer = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataRo: function(branchId) {
      this.loading = true;
      useNetw
        .get("api/quotation/repair-orders", {
          params: {
            page: this.currentPageCreate,
            perPage: this.perPageCreate,
            branchId: branchId,
          },
        })
        .then((response) => {
          this.rowsRo = response.data.data;
          // this.rowsCreate.total = response.data.total;
          this.rowsRo.total = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          this.isHidden = true;
        });
    },
    updateShow() {
      this.loading = true;
      useNetw
        .put("api/quotation/update", {
          qnId: this.qnId,
          svId: this.selectSV.svId != null ? this.selectSV.svId : undefined,
          roId: this.selectRO.roId != null ? this.selectRO.roId : undefined,
          cusId: this.cusName.cusId != null ? this.cusName.cusId : undefined,
          vehicleId:
            this.vehicle.vehicleId != null ? this.vehicle.vehicleId : undefined,
          qnDate: this.qnDate,
          qnDept: this.qnDept,
          note: this.note,
          qnTypeId: this.qnTypeId.nameTh,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          this.isHidden = true;
        });
    },
    submitDesc() {
      this.loading = true;
      useNetw
        .put("api/quotation/detail/update-description", {
          qndId: parseInt(this.qnId),
          srt: this.srt != null ? this.srt : undefined,
          approved: this.approved != null ? this.approved : undefined,
          noteDesc: this.noteDesc != null ? this.noteDesc : undefined,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          this.isHidden = true;
        });
    },
    getDataSv: function(branchId) {
      this.loading = true;
      useNetw
        .get("api/service", {
          params: {
            page: this.currentPageCreate,
            perPage: this.perPageCreate,
            "branchId[]":
              this.showBranchId != null ? this.showBranchId.branchId : branchId,
          },
        })
        .then((response) => {
          this.rowsSv = response.data.data;
          // this.rowsCreate.total = response.data.total;
          this.rowsRo.total = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          this.isHidden = true;
        });
    },
    onRowSelectedRo(rows) {
      //   this.alertConfirm(rows[0].roId);
      this.getDataSv(rows.branchId);
      this.getDataRo(rows.branchId);
      this.selectSV = null;
      this.selectRO = null;
      this.cusName = null;
      this.vehicle = null;
      this.qnDate = null;
      this.qnDept = null;
      this.qnTypeId = null;
      this.note = null;
    },
    alertConfirm(roId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการออกใบกำกับภาษีหรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.submitQuotation(roId);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    handleSearchCreate() {
      this.getDataRo();
    },
    handleChangePageCreate(page) {
      this.currentPageCreate = page;
      this.getDataRo();
    },
    handlePageChangeCreate(active) {
      this.perPageCreate = active;
      this.currentPageCreate = 1;
      this.getDataRo();
    },
    submitQuotation: function() {
      useNetw
        .post("api/quotation/store", {
          branchId: this.selectedBranch.branchId,
          svId: this.selectSV.svId,
          roId: this.selectRO.roId,
          cusId: this.cusName.cusId != null ? this.cusName.cusId : undefined,
          vehicleId: this.vehicle.vehicleId,
          qnDate: this.qnDate,
          qnDept: this.qnDept,
          qnTypeId: this.qnTypeId.nameTh,
          note: this.note,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "quotation-detail",
            params: { qnId: response.data.qnId },
          });
          this.getData(response.data.qnId);
          this.getDetail(response.data.qnId);
          // this.getData(response.data.taxInvId);
          // this.getDataRo();
          // this.getDataWagePart(response.data.taxInvId);
          // this.$refs.modalCreateTaxInvoice.hide();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
        });
    },
    getDetail: function(qnId) {
      this.isLoading = true;
      useNetw
        .get("api/quotation/detail", {
          params: {
            qnId: this.qnId != null ? this.qnId : qnId,
          },
        })
        .then((response) => {
          this.rowRoAll = response.data.data;
          // let arr = [];
          this.rowRoAll.forEach((element) => {
            // เข้าถึง array
            if (element.type === "W") {
              this.rowRoWages.push(element);
              this.rowRoWages.forEach((item, index) => {
                this.rowRoWages[index].selectWage = {
                  wageId: item.wageId,
                  nameTh: item.wageNameTh,
                };
              });
            }
            if (element.type === "P") {
              this.rowRoParts.push(element);
              this.rowRoParts.forEach((item, index) => {
                this.rowRoParts[index].selectPart = {
                  partId: item.partId,
                  nameTh: item.partNameTh,
                };
              });
            }
            if (element.type === "L") {
              this.rowRoLiquid.push(element);
              this.rowRoLiquid.forEach((item, index) => {
                this.rowRoLiquid[index].selectLiquid = {
                  partId: item.liquidId,
                  nameTh: item.liquidNameTh,
                };
              });
            }
            if (element.type === "OS") {
              this.rowRoOs.push(element);
              this.rowRoOs.forEach((item, index) => {
                this.rowRoOs[index].selectOs = {
                  osId: item.osId,
                  nameTh: item.osNameTh,
                };
              });
            }
            if (element.type === "OTH") {
              this.rowRoExtra.push(element);
              this.rowRoExtra.forEach((item, index) => {
                this.rowRoExtra[index].selectExtra = {
                  extraId: item.extraId,
                  nameTh: item.extraNameTh,
                };
              });
            }
            // if (element.type === "W") {
            //   this.rowRoWages[index].selectWage = {
            //     wageId: element.wageId,
            //     nameTh: element.wageNameTh,
            //   };
            //   this.rowRoWagesss[index].selectWage = {
            //     wageId: element.wageId,
            //     nameTh: element.wageNameTh,
            //   };
            // } else if (element.type === "P") {
            //   this.rowRoParts[index].selectPart = {
            //     partId: element.partId,
            //     nameTh: element.partNameTh,
            //   };
            // } else if (element.type === "L") {
            //   this.rowRoLiquid[index].selectLiquid = {
            //     partId: element.liquidId,
            //     nameTh: element.liquidNameTh,
            //   };
            // } else if (element.type === "OS") {
            //   this.rowRoOs[index].selectOs = {
            //     osId: element.osId,
            //     nameTh: element.osNameTh,
            //   };
            // } else if (element.type === "OTH") {
            //   this.rowRoExtra[index].selectExtra = {
            //     extraId: element.extraId,
            //     nameTh: element.extraNameTh,
            //   };
            // }
          });
          this.totalWage(this.rowRoWages);
          this.calculateTotalExtra();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalPart();
          this.calculateTotalWage();
          this.calculateTotal();
        })
        .catch((err) => {
          if (err.data != null) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    getDataVehicle: function() {
      this.isLoading = true;
      useNetw
        .get("api/vehicle", {
          params: {
            page: 1,
            perPage: 100,
            "branchId[]": this.userBranchId,
          },
        })
        .then((response) => {
          this.rowsVehicle = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    // resetModal() {
    //   this.rowsRo.forEach((item, index) => {
    //     this.rowsRo[index].roId = item.roId;
    //     this.rowsRo[index].roId = null;
    //   });

    // },
    alertDelete(rowsWp, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบรายการหรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.calDeleteWp(rowsWp);
            this.deleteWpInvoice(rowsWp, index);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    updateWpInvoice: function(rowsWp) {
      this.calUpdateWp(rowsWp);
      this.overlayFlag = true;
      useNetw
        .put("api/tax-invoice/detail/update", {
          taxInvDetailId: rowsWp.taxInvDetailId,
          partId: rowsWp.partId != null ? rowsWp.partId : undefined,
          wageId: rowsWp.wageId != null ? rowsWp.wageId : undefined,
          type: rowsWp.type,
          amount: rowsWp.amount,
          pricePerOne: rowsWp.pricePerOne,
          totalPrice: rowsWp.totalPrice,
          dcPer: rowsWp.dcPer,
          dcPrice: rowsWp.dcPrice,
          netPrice: rowsWp.netPrice,
          vatPer: rowsWp.vatPer,
          vatPrice: rowsWp.vatPrice,
          grandTotalPrice: rowsWp.grandTotalPrice,
          taxInvTotalPrice: this.wpSelect.totalPrice,
          taxInvDcPer: this.dcPer,
          taxInvDcPrice: this.dcPrice,
          taxInvNetPrice: this.wpSelect.netPrice,
          taxInvVatPer: this.vatPer,
          taxInvVatPrice: this.wpSelect.vatPrice,
          taxInvGrandTotalPrice: this.wpSelect.grandTotalPrice,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
          this.getData();
          // this.$refs["modalAmout"].hide();
          // this.getDataShowPart();
          //   this.getPoPart(this.poId);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    updateRoWage(
      wageId,
      price,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice,
      wageItem
    ) {
      this.wage.wageId = wageId;
      this.wage.pricePerOne = price;
      this.wage.amount = amount;
      this.wage.totalPrice = totalPrice;
      this.wage.dcPer = dcPer;
      this.wage.dcPrice = dcPrice;
      this.wage.netPrice = netPrice;
      this.qnVatPrice = (price / 100) * 7;
      this.qnGrandTotalPrice = parseFloat(price) + parseFloat(this.qnVatPrice);
      this.wageQnId = wageItem.qndId;

      useNetw
        .put("api/quotation/detail/update", {
          qndId: this.wageQnId,
          partId: this.partId,
          wageId: this.wage.wageId,
          osId: this.osId,
          liquidId: this.liquidId,
          extraId: this.extraId,
          type: "W",
          amount: this.wage.amount,
          pricePerOne: this.wage.pricePerOne,
          totalPrice: this.wage.totalPrice,
          dcPer: this.wage.dcPer,
          dcPrice: this.wage.dcPrice,
          netPrice: this.wage.netPrice,
          vatPer: 7,
          vatPrice: this.qnVatPrice,
          grandTotalPrice: this.qnGrandTotalPrice,
          // wage
          qnWageTotalPrice: this.roWageTotalPrice,
          qnWageDcPer: this.roWageDcPer,
          qnWageDcPrice: this.roWageDcPrice,
          qnWageNetPrice: this.roWagePrice,
          // part
          qnPartTotalPrice: this.roPartTotalPrice,
          qnPartDcPer: this.roPartDcPer,
          qnPartDcPrice: this.roPartDcPrice,
          qnPartNetPrice: this.roPartNetPrice,
          // os
          qnOsTotalPrice: this.roOsTotalPrice,
          qnOsDcPer: this.roOsDcPer,
          qnOsDcPrice: this.roOsDcPrice,
          qnOsNetPrice: this.roOsNetPrice,
          // liquid
          qnLiquidTotalPrice: this.roLiquidTotalPrice,
          qnLiquidDcPer: this.roLiquidDcPer,
          qnLiquidDcPrice: this.roLiquidDcPrice,
          qnLiquidNetPrice: this.roLiquidNetPrice,
          // extra
          qnExtraTotalPrice: this.roExtraTotalPrice,
          qnExtraDcPer: this.roExtraDcPer,
          qnExtraDcPrice: this.roExtraDcPrice,
          qnExtraNetPrice: this.roExtraNetPrice,
          // master
          qnTotalPrice: this.roTotalPrice,
          qnDcPer: this.roDcPer,
          qnDcPrice: this.roDcPrice,
          qnNetPrice: this.roNetPrice,
          qnVatPer: this.roVatPer,
          qnVatPrice: this.roVatPrice,
          qnGrandTotalPrice: this.roGrandTotalPrice,
          qnWhtPer: this.roWhtPer.id != undefined ? this.roWhtPer.id : 0,
          qnWhtPrice: this.roWhtPrice,
          qnPaymentAmount: this.roPaymentAmount,
        })

        .then((response) => {
          this.showAlert(response.data.message);
          // this.totalWage();
          this.calculateTotal();
          this.calculateTotalWage();
          this.calculateTotalPart();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalExtra();

          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getData();
          // this.getDetail();
          // this.getData();

          // this.overlayFlag = false;
        });
    },
    updateRoPart(
      partId,
      sellPrice,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice,
      partItem
    ) {
      this.part.partId = partId;
      this.part.pricePerOne = sellPrice;
      this.part.amount = amount;
      this.part.totalPrice = totalPrice;
      this.part.dcPer = dcPer;
      this.part.dcPrice = dcPrice;
      this.part.netPrice = netPrice;
      this.qnVatPrice = (sellPrice / 100) * 7;
      this.qnGrandTotalPrice =
        parseFloat(sellPrice) + parseFloat(this.qnVatPrice);
      // this.overlayFlag = true;

      useNetw
        .put("api/quotation/detail/update", {
          qndId: partItem.qndId,
          partId: this.part.partId,
          // wageId: this.wage.wageId,
          // osId: this.osId,
          // liquidId: this.liquidId,
          // extraId: this.extraId,
          type: "P",
          amount: this.part.amount,
          pricePerOne: this.part.pricePerOne,
          totalPrice: this.part.totalPrice,
          dcPer: this.part.dcPer,
          dcPrice: this.part.dcPrice,
          netPrice: this.part.netPrice,
          vatPer: 7,
          vatPrice: this.qnVatPrice,
          grandTotalPrice: this.qnGrandTotalPrice,
          // wage
          qnWageTotalPrice: this.roWageTotalPrice,
          qnWageDcPer: this.roWageDcPer,
          qnWageDcPrice: this.roWageDcPrice,
          qnWageNetPrice: this.roWagePrice,
          // part
          qnPartTotalPrice: this.roPartTotalPrice,
          qnPartDcPer: this.roPartDcPer,
          qnPartDcPrice: this.roPartDcPrice,
          qnPartNetPrice: this.roPartNetPrice,
          // os
          qnOsTotalPrice: this.roOsTotalPrice,
          qnOsDcPer: this.roOsDcPer,
          qnOsDcPrice: this.roOsDcPrice,
          qnOsNetPrice: this.roOsNetPrice,
          // liquid
          qnLiquidTotalPrice: this.roLiquidTotalPrice,
          qnLiquidDcPer: this.roLiquidDcPer,
          qnLiquidDcPrice: this.roLiquidDcPrice,
          qnLiquidNetPrice: this.roLiquidNetPrice,
          // extra
          qnExtraTotalPrice: this.roExtraTotalPrice,
          qnExtraDcPer: this.roExtraDcPer,
          qnExtraDcPrice: this.roExtraDcPrice,
          qnExtraNetPrice: this.roExtraNetPrice,
          // master
          qnTotalPrice: this.roTotalPrice,
          qnDcPer: this.roDcPer,
          qnDcPrice: this.roDcPrice,
          qnNetPrice: this.roNetPrice,
          qnVatPer: this.roVatPer,
          qnVatPrice: this.roVatPrice,
          qnGrandTotalPrice: this.roGrandTotalPrice,
          qnWhtPer: this.roWhtPer.id != undefined ? this.roWhtPer.id : 0,
          qnWhtPrice: this.roWhtPrice,
          qnPaymentAmount: this.roPaymentAmount,
        })

        .then((response) => {
          this.showAlert(response.data.message);
          // this.totalPart();
          this.calculateTotal();
          this.calculateTotalWage();
          this.calculateTotalPart();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalExtra();
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    updateRoLiquid(
      partId,
      sellPrice,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice,
      liquid
    ) {
      this.liquid.partId = partId;
      this.liquid.pricePerOne = sellPrice;
      this.liquid.amount = amount;
      this.liquid.totalPrice = totalPrice;
      this.liquid.dcPer = dcPer;
      this.liquid.dcPrice = dcPrice;
      this.liquid.netPrice = netPrice;
      this.qnVatPrice = (sellPrice / 100) * 7;
      this.qnGrandTotalPrice =
        parseFloat(sellPrice) + parseFloat(this.qnVatPrice);
      this.liquidQnId = liquid.qndId;
      // this.overlayFlag = true;
      useNetw
        .put("api/quotation/detail/update", {
          qndId: this.liquidQnId,
          // partId: this.partId,
          // wageId: this.wage.wageId,
          // osId: this.osId,
          liquidId: liquid.liquidId,
          // extraId: this.extraId,
          type: "L",
          amount: this.liquid.amount,
          pricePerOne: this.liquid.pricePerOne,
          totalPrice: this.liquid.totalPrice,
          dcPer: this.liquid.dcPer,
          dcPrice: this.liquid.dcPrice,
          netPrice: this.liquid.netPrice,
          vatPer: 7,
          vatPrice: this.qnVatPrice,
          grandTotalPrice: this.qnGrandTotalPrice,
          // wage
          qnWageTotalPrice: this.roWageTotalPrice,
          qnWageDcPer: this.roWageDcPer,
          qnWageDcPrice: this.roWageDcPrice,
          qnWageNetPrice: this.roWagePrice,
          // part
          qnPartTotalPrice: this.roPartTotalPrice,
          qnPartDcPer: this.roPartDcPer,
          qnPartDcPrice: this.roPartDcPrice,
          qnPartNetPrice: this.roPartNetPrice,
          // os
          qnOsTotalPrice: this.roOsTotalPrice,
          qnOsDcPer: this.roOsDcPer,
          qnOsDcPrice: this.roOsDcPrice,
          qnOsNetPrice: this.roOsNetPrice,
          // liquid
          qnLiquidTotalPrice: this.roLiquidTotalPrice,
          qnLiquidDcPer: this.roLiquidDcPer,
          qnLiquidDcPrice: this.roLiquidDcPrice,
          qnLiquidNetPrice: this.roLiquidNetPrice,
          // extra
          qnExtraTotalPrice: this.roExtraTotalPrice,
          qnExtraDcPer: this.roExtraDcPer,
          qnExtraDcPrice: this.roExtraDcPrice,
          qnExtraNetPrice: this.roExtraNetPrice,
          // master
          qnTotalPrice: this.roTotalPrice,
          qnDcPer: this.roDcPer,
          qnDcPrice: this.roDcPrice,
          qnNetPrice: this.roNetPrice,
          qnVatPer: this.roVatPer,
          qnVatPrice: this.roVatPrice,
          qnGrandTotalPrice: this.roGrandTotalPrice,
          qnWhtPer: this.roWhtPer.id != undefined ? this.roWhtPer.id : 0,
          qnWhtPrice: this.roWhtPrice,
          qnPaymentAmount: this.roPaymentAmount,
        })

        .then((response) => {
          this.showAlert(response.data.message);
          // this.totalPart();
          this.calculateTotal();
          this.calculateTotalWage();
          this.calculateTotalPart();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalExtra();
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    updateRoOs(
      osId,
      sellPrice,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice,
      osItem
    ) {
      this.os.osId = osId;
      this.os.pricePerOne = sellPrice;
      this.os.amount = amount;
      this.os.totalPrice = totalPrice;
      this.os.dcPer = dcPer;
      this.os.dcPrice = dcPrice;
      this.os.netPrice = netPrice;
      this.qnVatPrice = (sellPrice / 100) * 7;
      this.qnGrandTotalPrice =
        parseFloat(sellPrice) + parseFloat(this.qnVatPrice);
      // this.overlayFlag = true;

      useNetw
        .put("api/quotation/detail/update", {
          qndId: osItem.qndId,
          // partId: this.partId,
          // wageId: this.wage.wageId,
          osId: this.os.osId,
          // liquidId: this.liquidId,
          // extraId: this.extraId,
          type: "OS",
          amount: this.os.amount,
          pricePerOne: this.os.pricePerOne,
          totalPrice: this.os.totalPrice,
          dcPer: this.os.dcPer,
          dcPrice: this.os.dcPrice,
          netPrice: this.os.netPrice,
          vatPer: 7,
          vatPrice: this.qnVatPrice,
          grandTotalPrice: this.qnGrandTotalPrice,
          // wage
          qnWageTotalPrice: this.roWageTotalPrice,
          qnWageDcPer: this.roWageDcPer,
          qnWageDcPrice: this.roWageDcPrice,
          qnWageNetPrice: this.roWagePrice,
          // part
          qnPartTotalPrice: this.roPartTotalPrice,
          qnPartDcPer: this.roPartDcPer,
          qnPartDcPrice: this.roPartDcPrice,
          qnPartNetPrice: this.roPartNetPrice,
          // os
          qnOsTotalPrice: this.roOsTotalPrice,
          qnOsDcPer: this.roOsDcPer,
          qnOsDcPrice: this.roOsDcPrice,
          qnOsNetPrice: this.roOsNetPrice,
          // liquid
          qnLiquidTotalPrice: this.roLiquidTotalPrice,
          qnLiquidDcPer: this.roLiquidDcPer,
          qnLiquidDcPrice: this.roLiquidDcPrice,
          qnLiquidNetPrice: this.roLiquidNetPrice,
          // extra
          qnExtraTotalPrice: this.roExtraTotalPrice,
          qnExtraDcPer: this.roExtraDcPer,
          qnExtraDcPrice: this.roExtraDcPrice,
          qnExtraNetPrice: this.roExtraNetPrice,
          // master
          qnTotalPrice: this.roTotalPrice,
          qnDcPer: this.roDcPer,
          qnDcPrice: this.roDcPrice,
          qnNetPrice: this.roNetPrice,
          qnVatPer: this.roVatPer,
          qnVatPrice: this.roVatPrice,
          qnGrandTotalPrice: this.roGrandTotalPrice,
          qnWhtPer: this.roWhtPer.id != undefined ? this.roWhtPer.id : 0,
          qnWhtPrice: this.roWhtPrice,
          qnPaymentAmount: this.roPaymentAmount,
        })

        .then((response) => {
          this.showAlert(response.data.message);
          // this.totalPart();
          this.calculateTotal();
          this.calculateTotalWage();
          this.calculateTotalPart();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalExtra();
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    updateRoExtra(
      extraId,
      sellPrice,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice,
      extra
    ) {
      this.extra.extraId = extraId;
      this.extra.pricePerOne = sellPrice;
      this.extra.amount = amount;
      this.extra.totalPrice = totalPrice;
      this.extra.dcPer = dcPer;
      this.extra.dcPrice = dcPrice;
      this.extra.netPrice = netPrice;
      this.qnVatPrice = (sellPrice / 100) * 7;
      this.qnGrandTotalPrice =
        parseFloat(sellPrice) + parseFloat(this.qnVatPrice);
      // this.overlayFlag = true;
      this.extraQnId = extra.qndId;

      useNetw
        .put("api/quotation/detail/update", {
          qndId: this.extraQnId,
          // partId: this.partId,
          // wageId: this.wage.wageId,
          // osId: this.osId,
          // liquidId: this.liquidId,
          extraId: this.extra.extraId,
          type: "OTH",
          amount: this.extra.amount,
          pricePerOne: this.extra.pricePerOne,
          totalPrice: this.extra.totalPrice,
          dcPer: this.extra.dcPer,
          dcPrice: this.extra.dcPrice,
          netPrice: this.extra.netPrice,
          vatPer: 7,
          vatPrice: this.qnVatPrice,
          grandTotalPrice: this.qnGrandTotalPrice,

          // wage
          qnWageTotalPrice: this.roWageTotalPrice,
          qnWageDcPer: this.roWageDcPer,
          qnWageDcPrice: this.roWageDcPrice,
          qnWageNetPrice: this.roWagePrice,
          // part
          qnPartTotalPrice: this.roPartTotalPrice,
          qnPartDcPer: this.roPartDcPer,
          qnPartDcPrice: this.roPartDcPrice,
          qnPartNetPrice: this.roPartNetPrice,
          // os
          qnOsTotalPrice: this.roOsTotalPrice,
          qnOsDcPer: this.roOsDcPer,
          qnOsDcPrice: this.roOsDcPrice,
          qnOsNetPrice: this.roOsNetPrice,
          // liquid
          qnLiquidTotalPrice: this.roLiquidTotalPrice,
          qnLiquidDcPer: this.roLiquidDcPer,
          qnLiquidDcPrice: this.roLiquidDcPrice,
          qnLiquidNetPrice: this.roLiquidNetPrice,
          // extra
          qnExtraTotalPrice: this.roExtraTotalPrice,
          qnExtraDcPer: this.roExtraDcPer,
          qnExtraDcPrice: this.roExtraDcPrice,
          qnExtraNetPrice: this.roExtraNetPrice,
          // master
          qnTotalPrice: this.roTotalPrice,
          qnDcPer: this.roDcPer,
          qnDcPrice: this.roDcPrice,
          qnNetPrice: this.roNetPrice,
          qnVatPer: this.roVatPer,
          qnVatPrice: this.roVatPrice,
          qnGrandTotalPrice: this.roGrandTotalPrice,
          qnWhtPer: this.roWhtPer.id != undefined ? this.roWhtPer.id : 0,
          qnWhtPrice: this.roWhtPrice,
          qnPaymentAmount: this.roPaymentAmount,
        })

        .then((response) => {
          this.showAlert(response.data.message);
          // this.totalPart();
          this.calculateTotal();
          this.calculateTotalWage();
          this.calculateTotalPart();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalExtra();
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getRoPart: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/ro/parts", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            svId: atob(this.svIdCode),
            roId: this.roShowId != null ? this.roShowId : this.roId,
          },
        })
        .then((response) => {
          this.rowRoParts = response.data.data;
          this.rowRoParts.forEach((element, index) => {
            this.rowRoParts[index].selectPart = {
              // เข้าถึง array
              partId: element.partId,
              nameTh: element.partNameTh,
            };
          });
          this.totalPart(this.rowRoParts);
          this.calculateTotalExtra();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalPart();
          this.calculateTotalWage();
          this.calculateTotal();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    upsertWage(selectWage, wage) {
      // this.upRoId = roId;
      if (wage.wageId.length === 0) {
        wage.wageId = selectWage.wageId;
        wage.amount = 1;
        wage.pricePerOne = selectWage.price;
        wage.totalPrice = selectWage.price;
        wage.dcPer = 0;
        wage.dcPrice = 0;
        wage.netPrice = selectWage.price;
        wage.selectWage = selectWage;
        this.calculateTotalExtra();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.totalWage(wage);
        this.calculateTotalWage();
        this.calculateTotal();
        this.calculateTotalPart();
        this.postRoStore(wage);
      } else {
        // ถ้ามีใบสั่งซ่อมซ้ำให้ทำการอัพเดต
        wage.wageId = selectWage.wageId;
        wage.amount = 1;
        wage.pricePerOne = selectWage.price;
        wage.totalPrice = selectWage.price;
        wage.dcPer = 0;
        wage.dcPrice = 0;
        wage.netPrice = selectWage.price;
        wage.selectWage = selectWage;
        this.calculateTotalExtra();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.totalWage(wage);
        this.calculateTotalWage();
        this.calculateTotal();
        this.calculateTotalPart();
        this.updateRoWage(
          wage.wageId,
          wage.pricePerOne,
          wage.amount,
          wage.totalPrice,
          wage.dcPer,
          wage.dcPrice,
          wage.netPrice,
          wage
        ); //, wage.pricePerOne
      }
    },
    upsertPart(selectPart, part) {
      if (part.partId.length === 0) {
        part.partId = selectPart.partId;
        part.amount = 1;
        part.pricePerOne = selectPart.sellPrice;
        part.totalPrice = selectPart.sellPrice;
        part.dcPer = 0;
        part.dcPrice = 0;
        part.netPrice = selectPart.sellPrice;
        part.selectPart = selectPart;
        this.calculateTotalExtra();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalWage();
        this.calculateTotal();
        this.totalPart(part);
        this.calculateTotalPart();
        this.postRoStore(part);
      } else {
        part.partId = selectPart.partId;
        part.amount = 1;
        part.pricePerOne = selectPart.sellPrice;
        part.totalPrice = selectPart.sellPrice;
        part.dcPer = 0;
        part.dcPrice = 0;
        part.netPrice = selectPart.sellPrice;
        this.calculateTotalExtra();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalWage();
        this.calculateTotal();
        this.totalPart(part);
        this.calculateTotalPart();
        this.updateRoPart(
          part.partId,
          part.pricePerOne,
          part.amount,
          part.totalPrice,
          part.dcPer,
          part.dcPrice,
          part.netPrice,
          part
        );
      }
    },
    upsertLiquid(selectLiquid, liquid) {
      if (liquid.partId.length === 0) {
        liquid.partId = selectLiquid.partId;
        liquid.amount = 1;
        liquid.pricePerOne =
          selectLiquid.sellPrice != null ? selectLiquid.sellPrice : 0;
        liquid.totalPrice =
          selectLiquid.sellPrice != null ? selectLiquid.sellPrice : 0;
        liquid.dcPer = 0;
        liquid.dcPrice = 0;
        liquid.netPrice =
          selectLiquid.sellPrice != null ? selectLiquid.sellPrice : 0;
        liquid.selectLiquid = selectLiquid;
        this.calculateTotalExtra();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalWage();
        this.calculateTotal();
        this.totalLiquid(liquid);
        this.calculateTotalPart();
        this.postRoStore(liquid);
      } else {
        liquid.partId = selectLiquid.partId;
        liquid.amount = 1;
        liquid.pricePerOne =
          selectLiquid.sellPrice != null ? selectLiquid.sellPrice : 0;
        liquid.totalPrice =
          selectLiquid.sellPrice != null ? selectLiquid.sellPrice : 0;
        liquid.dcPer = 0;
        liquid.dcPrice = 0;
        liquid.netPrice =
          selectLiquid.sellPrice != null ? selectLiquid.sellPrice : 0;
        this.calculateTotalExtra();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalWage();
        this.calculateTotal();
        this.totalLiquid(liquid);
        this.calculateTotalPart();
        this.updateRoLiquid(
          liquid.partId,
          liquid.pricePerOne,
          liquid.amount,
          liquid.totalPrice,
          liquid.dcPer,
          liquid.dcPrice,
          liquid.netPrice,
          liquid
        );
      }
    },
    upsertOs(selectOs, os) {
      if (os.osId.length === 0) {
        os.osId = selectOs.osId;
        os.amount = 1;
        os.pricePerOne = selectOs.price != null ? selectOs.price : 0;
        os.totalPrice = selectOs.price != null ? selectOs.price : 0;
        os.dcPer = 0;
        os.dcPrice = 0;
        os.netPrice = selectOs.price != null ? selectOs.price : 0;
        os.selectOs = selectOs;

        this.totalOs(os);
        this.calculateTotal();
        this.calculateTotalWage();
        this.calculateTotalPart();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalExtra();
        this.postRoStore(os);
      } else {
        os.osId = selectOs.osId;
        os.amount = 1;
        os.pricePerOne = selectOs.price != null ? selectOs.price : 0;
        os.totalPrice = selectOs.price != null ? selectOs.price : 0;
        os.dcPer = 0;
        os.dcPrice = 0;
        os.netPrice = selectOs.price != null ? selectOs.price : 0;

        this.totalOs(os);
        this.calculateTotal();
        this.calculateTotalWage();
        this.calculateTotalPart();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalExtra();
        this.updateRoOs(
          os.osId,
          os.pricePerOne,
          os.amount,
          os.totalPrice,
          os.dcPer,
          os.dcPrice,
          os.netPrice,
          os
        );
      }
    },
    upsertExtra(selectExtra, extra) {
      if (extra.extraId.length === 0) {
        extra.extraId = selectExtra.extraId;
        extra.amount = 1;
        extra.pricePerOne = selectExtra.price != null ? selectExtra.price : 0;
        extra.totalPrice = selectExtra.price != null ? selectExtra.price : 0;
        extra.dcPer = 0;
        extra.dcPrice = 0;
        extra.netPrice = selectExtra.price != null ? selectExtra.price : 0;
        extra.selectOs = selectExtra;

        this.totalExtra(extra);
        this.calculateTotal();
        this.calculateTotalWage();
        this.calculateTotalPart();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalExtra();
        this.postRoStore(extra);
      } else {
        extra.extraId = selectExtra.extraId;
        extra.amount = 1;
        extra.pricePerOne = selectExtra.price != null ? selectExtra.price : 0;
        extra.totalPrice = selectExtra.price != null ? selectExtra.price : 0;
        extra.dcPer = 0;
        extra.dcPrice = 0;
        extra.netPrice = selectExtra.price != null ? selectExtra.price : 0;

        this.totalExtra(extra);
        this.calculateTotal();
        this.calculateTotalWage();
        this.calculateTotalPart();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalExtra();
        this.updateRoExtra(
          extra.extraId,
          extra.pricePerOne,
          extra.amount,
          extra.totalPrice,
          extra.dcPer,
          extra.dcPrice,
          extra.netPrice,
          extra
        );
      }
    },

    postRoStore: function(item) {
      this.overlayFlag = true;
      const bodyFormData = new FormData();
      this.beforWage = item.wageId;
      this.wage_Id = item.wageId;
      this.qnVatPrice = (item.pricePerOne / 100) * 7;
      this.qnGrandTotalPrice = parseFloat(item.pricePerOne) + this.qnVatPrice;

      // // parse
      bodyFormData.append("qnId", this.qnId);
      if (item.selectPart != null) {
        bodyFormData.append("partId", item.selectPart.partId);
        bodyFormData.append("type", "P");
      }
      if (item.wageId != null) {
        bodyFormData.append("wageId", item.wageId);
        bodyFormData.append("type", "W");
      }
      if (item.osId != null) {
        bodyFormData.append("osId", item.osId);
        bodyFormData.append("type", "OS");
      }
      if (item.selectLiquid != null) {
        bodyFormData.append("liquidId", item.selectLiquid.partId);
        bodyFormData.append("type", "L");
      }
      if (item.extraId != null) {
        bodyFormData.append("extraId", item.extraId);
        bodyFormData.append("type", "OTH");
      }
      // bodyFormData.append("type", "W");
      bodyFormData.append("amount", item.amount);
      bodyFormData.append("pricePerOne", item.pricePerOne);
      bodyFormData.append("totalPrice", item.totalPrice);
      bodyFormData.append("dcPer", item.dcPer);
      bodyFormData.append("dcPrice", item.dcPrice);
      bodyFormData.append("netPrice", item.netPrice);
      bodyFormData.append("vatPer", item.vatPer != null ? item.vatPer : 7);
      bodyFormData.append("vatPrice", this.qnVatPrice);
      bodyFormData.append("grandTotalPrice", this.qnGrandTotalPrice);
      // wage
      bodyFormData.append("qnWageTotalPrice", this.roWageTotalPrice);
      bodyFormData.append("qnWageDcPer", this.roWageDcPer);
      bodyFormData.append("qnWageDcPrice", this.roWageDcPrice);
      bodyFormData.append("qnWageNetPrice", this.roWagePrice);
      // part
      bodyFormData.append("qnPartTotalPrice", this.roPartTotalPrice);
      bodyFormData.append("qnPartDcPer", this.roPartDcPer);
      bodyFormData.append("qnPartDcPrice", this.roPartDcPrice);
      bodyFormData.append("qnPartNetPrice", this.roPartNetPrice);
      // os
      bodyFormData.append("qnOsTotalPrice", this.roOsTotalPrice);
      bodyFormData.append("qnOsDcPer", this.roOsDcPer);
      bodyFormData.append("qnOsDcPrice", this.roOsDcPrice);
      bodyFormData.append("qnOsNetPrice", this.roOsNetPrice);
      // liquid
      bodyFormData.append("qnLiquidTotalPrice", this.roLiquidTotalPrice);
      bodyFormData.append("qnLiquidDcPer", this.roLiquidDcPer);
      bodyFormData.append("qnLiquidDcPrice", this.roLiquidDcPrice);
      bodyFormData.append("qnLiquidNetPrice", this.roLiquidNetPrice);
      // extra
      bodyFormData.append("qnExtraTotalPrice", this.roExtraTotalPrice);
      bodyFormData.append("qnExtraDcPer", this.roExtraDcPer);
      bodyFormData.append("qnExtraDcPrice", this.roExtraDcPrice);
      bodyFormData.append("qnExtraNetPrice", this.roExtraNetPrice);
      // master
      bodyFormData.append("qnTotalPrice", this.roTotalPrice);
      bodyFormData.append("qnDcPer", this.roDcPer);
      bodyFormData.append("qnDcPrice", this.roDcPrice);
      bodyFormData.append("qnNetPrice", this.roNetPrice);
      bodyFormData.append("qnVatPer", this.roVatPer);
      bodyFormData.append("qnVatPrice", this.roVatPrice);
      bodyFormData.append("qnGrandTotalPrice", this.roGrandTotalPrice);
      bodyFormData.append(
        "qnWhtPer",
        this.roWhtPer.id != undefined ? this.roWhtPer.id : 0
      );
      bodyFormData.append("qnWhtPrice", this.roWhtPrice);
      bodyFormData.append("qnPaymentAmount", this.roPaymentAmount);

      useNetw
        .post("api/quotation/detail/store", bodyFormData)
        .then((response) => {
          this.qndId = response.data.qndId;
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.getData();
          // this.getDetail();

          // this.$router.push({ name: "branch-wages" });
        });
    },

    // postRoStore: function(part) {
    //   this.overlayFlag = true;
    //   const bodyFormData = new FormData();
    //   this.qnVatPrice = (part.pricePerOne / 100) * 7;
    //   this.qnGrandTotalPrice = parseFloat(part.netPrice) + this.qnVatPrice;

    //   // // parse
    //   bodyFormData.append("qnId", this.qnId);
    //   if (part.selectPart.partId != null) {
    //     bodyFormData.append("partId", part.selectPart.partId);
    //   }
    //   if (this.wage_Id != null) {
    //     bodyFormData.append("wageId", this.wage_Id);
    //   }
    //   if (this.osId != null) {
    //     bodyFormData.append("osId", this.osId);
    //   }
    //   if (this.liquidId != null) {
    //     bodyFormData.append("liquidId", this.liquidId);
    //   }
    //   if (this.extraId != null) {
    //     bodyFormData.append("extraId", this.extraId);
    //   }
    //   bodyFormData.append("type", "P");
    //   bodyFormData.append("amount", part.amount);
    //   bodyFormData.append("pricePerOne", part.pricePerOne);
    //   bodyFormData.append("totalPrice", part.totalPrice);
    //   bodyFormData.append("dcPer", part.dcPer);
    //   bodyFormData.append("dcPrice", part.dcPrice);
    //   bodyFormData.append("netPrice", part.netPrice);
    //   bodyFormData.append("vatPer", part.vatPer != null ? part.vatPer : 7);
    //   bodyFormData.append("vatPrice", this.qnVatPrice);
    //   bodyFormData.append("grandTotalPrice", this.qnGrandTotalPrice);
    //   // wage
    //   bodyFormData.append("qnWageTotalPrice", this.roWageTotalPrice);
    //   bodyFormData.append("qnWageDcPer", this.roWageDcPer);
    //   bodyFormData.append("qnWageDcPrice", this.roWageDcPrice);
    //   bodyFormData.append("qnWageNetPrice", this.roWagePrice);
    //   // part
    //   bodyFormData.append("qnPartTotalPrice", this.roPartTotalPrice);
    //   bodyFormData.append("qnPartDcPer", this.roPartDcPer);
    //   bodyFormData.append("qnPartDcPrice", this.roPartDcPrice);
    //   bodyFormData.append("qnPartNetPrice", this.roPartNetPrice);
    //   // os
    //   bodyFormData.append("qnOsTotalPrice", this.roOsTotalPrice);
    //   bodyFormData.append("qnOsDcPer", this.roOsDcPer);
    //   bodyFormData.append("qnOsDcPrice", this.roOsDcPrice);
    //   bodyFormData.append("qnOsNetPrice", this.roOsNetPrice);
    //   // liquid
    //   bodyFormData.append("qnLiquidTotalPrice", this.roLiquidTotalPrice);
    //   bodyFormData.append("qnLiquidDcPer", this.roLiquidDcPer);
    //   bodyFormData.append("qnLiquidDcPrice", this.roLiquidDcPrice);
    //   bodyFormData.append("qnLiquidNetPrice", this.roLiquidNetPrice);
    //   // extra
    //   bodyFormData.append("qnExtraTotalPrice", this.roExtraTotalPrice);
    //   bodyFormData.append("qnExtraDcPer", this.roExtraDcPer);
    //   bodyFormData.append("qnExtraDcPrice", this.roExtraDcPrice);
    //   bodyFormData.append("qnExtraNetPrice", this.roExtraNetPrice);
    //   // master
    //   bodyFormData.append("qnTotalPrice", this.roTotalPrice);
    //   bodyFormData.append("qnDcPer", this.roDcPer);
    //   bodyFormData.append("qnDcPrice", this.roDcPrice);
    //   bodyFormData.append("qnNetPrice", this.roNetPrice);
    //   bodyFormData.append("qnVatPer", this.roVatPer);
    //   bodyFormData.append("qnVatPrice", this.roVatPrice);
    //   bodyFormData.append("qnGrandTotalPrice", this.roGrandTotalPrice);
    //   bodyFormData.append(
    //     "qnWhtPer",
    //     this.roWhtPer.id != undefined ? this.roWhtPer.id : 0
    //   );
    //   bodyFormData.append("qnWhtPrice", this.roWhtPrice);
    //   bodyFormData.append("qnPaymentAmount", this.roPaymentAmount);

    //   useNetw
    //     .post("api/quotation/detail/store", bodyFormData)
    //     .then((response) => {
    //       this.showAlert(response.data.message);
    //       // Swal.fire(
    //       //   appConfig.swal.title.postSuccess,
    //       //   JSON.stringify(response.data.message),
    //       //   appConfig.swal.type.success
    //       // );
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(
    //           err.response.data.message +
    //             "<br>" +
    //             err.response.data.validationMessage
    //         ),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;

    //       // this.$router.push({ name: "branch-wages" });
    //     });
    // },

    calculateTotalWage() {
      var totalNetPriceCal, roWageTotalPriceCal, roWageDcPriceCal;
      totalNetPriceCal = this.rowRoWages.reduce(function(sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);

      this.roWagePrice = totalNetPriceCal.toFixed(2); //ค่าแรงสุทธิ

      roWageTotalPriceCal = this.rowRoWages.reduce(function(sum, item) {
        //รวมค่าแรง
        var totalPrice = parseFloat(item.totalPrice);
        if (!isNaN(totalPrice)) {
          return sum + totalPrice;
        }
      }, 0);

      this.roWageTotalPrice = roWageTotalPriceCal.toFixed(2); //รวมค่าแรง

      if (this.wage.dcMethod.per === "per") {
        let roWageDcPerCal = this.rowRoWages.reduce(function(sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);
        this.roWageDcPer = roWageDcPerCal.toFixed(2); //รวมส่วนลด บาท
      } else if (this.wage.dcMethod.bath === "bath") {
        let roWageDcPerCal = this.rowRoWages.reduce(function(sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);
        this.roWageDcPer = roWageDcPerCal.toFixed(2); //รวมส่วนลด บาท
      }

      roWageDcPriceCal = this.rowRoWages.reduce(function(sum, item) {
        //รวมส่วนลด บาท
        var dcPrice = parseFloat(item.dcPrice);
        if (!isNaN(dcPrice)) {
          return sum + dcPrice;
        }
      }, 0);

      this.roWageDcPrice = roWageDcPriceCal.toFixed(2); //รวมส่วนลด บาท
    },
    calculateTotalPart() {
      var roPartNetPriceCal, roPartTotalPriceCal, roPartDcPriceCal;
      roPartNetPriceCal = this.rowRoParts.reduce(function(sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);
      this.roPartNetPrice = roPartNetPriceCal.toFixed(2); //ค่าแรงสุทธิ #####

      roPartTotalPriceCal = this.rowRoParts.reduce(function(sum, item) {
        //รวมค่าแรง
        var totalPrice = parseFloat(item.totalPrice);
        if (!isNaN(totalPrice)) {
          return sum + totalPrice;
        }
      }, 0);

      this.roPartTotalPrice = roPartTotalPriceCal.toFixed(2); //รวมค่าแรง ####

      roPartDcPriceCal = this.rowRoParts.reduce(function(sum, item) {
        //รวมส่วนลด บาท
        var dcPrice = parseFloat(item.dcPrice);
        if (!isNaN(dcPrice)) {
          return sum + dcPrice;
        }
      }, 0);

      this.roPartDcPrice = roPartDcPriceCal.toFixed(2); //รวมส่วนลด บาท #####

      if (this.wage.dcMethod.per === "per") {
        let roPartDcPerCal = this.rowRoParts.reduce(function(sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roPartDcPer = roPartDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      } else if (this.part.dcMethod.bath === "bath") {
        let roPartDcPerCal = this.rowRoParts.reduce(function(sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roPartDcPer = roPartDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      }
    },
    calculateTotalOs() {
      var roOsNetPriceCal, roOsTotalPriceCal, roOsDcPriceCal;
      roOsNetPriceCal = this.rowRoOs.reduce(function(sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);
      this.roOsNetPrice = roOsNetPriceCal.toFixed(2); //ค่าแรงสุทธิ #####

      roOsTotalPriceCal = this.rowRoOs.reduce(function(sum, item) {
        //รวมค่าแรง
        var totalPrice = parseFloat(item.totalPrice);
        if (!isNaN(totalPrice)) {
          return sum + totalPrice;
        }
      }, 0);

      this.roOsTotalPrice = roOsTotalPriceCal.toFixed(2); //รวมค่าแรง ####

      roOsDcPriceCal = this.rowRoOs.reduce(function(sum, item) {
        //รวมส่วนลด บาท
        var dcPrice = parseFloat(item.dcPrice);
        if (!isNaN(dcPrice)) {
          return sum + dcPrice;
        }
      }, 0);

      this.roOsDcPrice = roOsDcPriceCal; //รวมส่วนลด บาท #####

      if (this.os.dcMethod.per === "per") {
        let roOsDcPerCal = this.rowRoOs.reduce(function(sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roOsDcPer = roOsDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      } else if (this.os.dcMethod.bath === "bath") {
        let roOsDcPerCal = this.rowRoOs.reduce(function(sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roOsDcPer = roOsDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      }
    },
    calculateTotalLiquid() {
      var roLiquidNetPriceCal, roLiquidTotalPriceCal, roLiquidDcPriceCal;
      roLiquidNetPriceCal = this.rowRoLiquid.reduce(function(sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);
      this.roLiquidNetPrice = roLiquidNetPriceCal.toFixed(2); //ค่าแรงสุทธิ #####

      roLiquidTotalPriceCal = this.rowRoLiquid.reduce(function(sum, item) {
        //รวมค่าแรง
        var totalPrice = parseFloat(item.totalPrice);
        if (!isNaN(totalPrice)) {
          return sum + totalPrice;
        }
      }, 0);

      this.roLiquidTotalPrice = roLiquidTotalPriceCal.toFixed(2); //รวมค่าแรง ####

      roLiquidDcPriceCal = this.rowRoLiquid.reduce(function(sum, item) {
        //รวมส่วนลด บาท
        var dcPrice = parseFloat(item.dcPrice);
        if (!isNaN(dcPrice)) {
          return sum + dcPrice;
        }
      }, 0);

      this.roLiquidDcPrice = roLiquidDcPriceCal; //รวมส่วนลด บาท #####

      if (this.liquid.dcMethod.per === "per") {
        let roLiquidDcPerCal = this.rowRoLiquid.reduce(function(sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roLiquidDcPer = roLiquidDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      } else if (this.liquid.dcMethod.bath === "bath") {
        let roLiquidDcPerCal = this.rowRoLiquid.reduce(function(sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roLiquidDcPer = roLiquidDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      }
    },
    calculateTotalExtra() {
      var roExtraNetPriceCal, roExtraTotalPriceCal, roExtraDcPriceCal;
      roExtraNetPriceCal = this.rowRoExtra.reduce(function(sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);
      this.roExtraNetPrice = roExtraNetPriceCal.toFixed(2); //ค่าแรงสุทธิ #####

      roExtraTotalPriceCal = this.rowRoExtra.reduce(function(sum, item) {
        //รวมค่าแรง
        var totalPrice = parseFloat(item.totalPrice);
        if (!isNaN(totalPrice)) {
          return sum + totalPrice;
        }
      }, 0);

      this.roExtraTotalPrice = roExtraTotalPriceCal.toFixed(2); //รวมค่าแรง ####

      roExtraDcPriceCal = this.rowRoExtra.reduce(function(sum, item) {
        //รวมส่วนลด บาท
        var dcPrice = parseFloat(item.dcPrice);
        if (!isNaN(dcPrice)) {
          return sum + dcPrice;
        }
      }, 0);

      this.roExtraDcPrice = roExtraDcPriceCal; //รวมส่วนลด บาท #####

      if (this.extra.dcMethod.per === "per") {
        let roExtraDcPerCal = this.rowRoExtra.reduce(function(sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roExtraDcPer = roExtraDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      } else if (this.extra.dcMethod.bath === "bath") {
        let roExtraDcPerCal = this.rowRoExtra.reduce(function(sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roExtraDcPer = roExtraDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      }
    },
    calculateTotal() {
      let roTotalPrice =
        parseFloat(this.roPartNetPrice) +
        parseFloat(this.roWagePrice) +
        parseFloat(this.roLiquidNetPrice) +
        parseFloat(this.roOsNetPrice) +
        parseFloat(this.roExtraNetPrice);

      if (!isNaN(roTotalPrice)) {
        //ไม่ให้เป็น NaN
        this.roTotalPrice = roTotalPrice.toFixed(2); //รวมอะไหล่ ค่าแรง%#####
      }

      let roDcPrice =
        parseFloat(this.roTotalPrice) * parseFloat(this.roDcPer / 100);
      if (!isNaN(roDcPrice)) {
        //ไม่ให้เป็น NaN
        this.roDcPrice = roDcPrice.toFixed(2); //ส่วนลด%#####
      }

      let roNetPrice = parseFloat(this.roTotalPrice - this.roDcPrice);
      if (!isNaN(roNetPrice)) {
        this.roNetPrice = roNetPrice.toFixed(2); //สุทธิ ####
      }

      let roVatPrice = this.roNetPrice * parseFloat(this.roVatPer / 100);
      if (!isNaN(roVatPrice)) {
        this.roVatPrice = roVatPrice.toFixed(2); //ภาาี ####
      }

      let roGrandTotalPrice =
        parseFloat(this.roNetPrice) + parseFloat(this.roVatPrice);
      if (!isNaN(roGrandTotalPrice)) {
        this.roGrandTotalPrice = roGrandTotalPrice.toFixed(2); //สุทธิ + ภาษี ####
      }

      let roWhtPrice = this.roNetPrice * (this.roWhtPer.id / 100);
      if (!isNaN(roWhtPrice)) {
        this.roWhtPrice = roWhtPrice.toFixed(2); //สุทธิ + ภาษี ####
      }

      let roPaymentAmount =
        parseFloat(this.roGrandTotalPrice) - parseFloat(this.roWhtPrice);
      if (!isNaN(roPaymentAmount)) {
        this.roPaymentAmount = roPaymentAmount.toFixed(2); //จำนวนเงินที่ต้องจ่าย ####
      }
    },
    //######แก้ไขส่วนของอะไหล่ ทำแก้ไข  แก้ตัวแปร
    totalWage(wage) {
      let totalPrices = parseFloat(wage.pricePerOne) * parseFloat(wage.amount); //มูลค่า
      if (!isNaN(totalPrices)) {
        //ไม่ให้เป็น NaN
        wage.totalPrice = totalPrices.toFixed(2);
      }

      // =========ส่วนลด
      var dcPrice = 0;
      if (this.wage.per) {
        dcPrice = parseFloat(wage.totalPrice) * parseFloat(wage.dcPer / 100); //ส่วนลด
        if (!isNaN(dcPrice)) {
          //ไม่ให้เป็น NaN
          wage.dcPrice = dcPrice.toFixed(2);
        }
      } else if (this.wage.bath) {
        wage.dcPrice = wage.dcPer;
      } else {
        wage.dcPrice = wage.dcPer;
      }

      let netPrice = parseFloat(wage.totalPrice) - parseFloat(wage.dcPrice); //สุทธิ(ลดแล้ว)
      if (!isNaN(netPrice)) {
        //ไม่ให้เป็น NaN
        wage.netPrice = netPrice.toFixed(2);
      }
      this.calculateTotalExtra();
      this.calculateTotalOs();
      this.calculateTotalLiquid();
      this.calculateTotalPart();
      this.calculateTotalWage();
      this.calculateTotal();
    },
    totalPart(part) {
      let totalPrice = parseFloat(part.pricePerOne) * parseFloat(part.amount); //มูลค่า
      if (!isNaN(totalPrice)) {
        //ไม่ให้เป็น NaN
        part.totalPrice = totalPrice.toFixed(2);
      }

      var dcPrice = 0;
      if (this.part.per) {
        dcPrice = parseFloat(part.totalPrice) * parseFloat(part.dcPer / 100); //ส่วนลด
        if (!isNaN(dcPrice)) {
          //ไม่ให้เป็น NaN
          part.dcPrice = dcPrice.toFixed(2);
        }
      } else if (this.part.bath) {
        part.dcPrice = part.dcPer;
      } else {
        part.dcPrice = part.dcPer;
      }

      let netPrice = parseFloat(part.totalPrice) - parseFloat(part.dcPrice); //สุทธิ(ลดแล้ว)
      if (!isNaN(netPrice)) {
        //ไม่ให้เป็น NaN
        part.netPrice = netPrice.toFixed(2);
      }
      this.calculateTotalExtra();
      this.calculateTotalOs();
      this.calculateTotalLiquid();
      this.calculateTotalPart();
      this.calculateTotalWage();
      this.calculateTotal();
    },
    totalLiquid(liquid) {
      let totalPrice =
        parseFloat(liquid.pricePerOne) * parseFloat(liquid.amount); //มูลค่า
      if (!isNaN(totalPrice)) {
        //ไม่ให้เป็น NaN
        liquid.totalPrice = totalPrice.toFixed(2);
      }

      var dcPrice = 0;
      if (this.liquid.per) {
        dcPrice =
          parseFloat(liquid.totalPrice) * parseFloat(liquid.dcPer / 100); //ส่วนลด
        if (!isNaN(dcPrice)) {
          //ไม่ให้เป็น NaN
          liquid.dcPrice = dcPrice.toFixed(2);
        }
      } else if (this.liquid.bath) {
        liquid.dcPrice = liquid.dcPer;
      } else {
        liquid.dcPrice = liquid.dcPer;
      }

      let netPrice = parseFloat(liquid.totalPrice) - parseFloat(liquid.dcPrice); //สุทธิ(ลดแล้ว)
      if (!isNaN(netPrice)) {
        //ไม่ให้เป็น NaN
        liquid.netPrice = netPrice.toFixed(2);
      }
      this.calculateTotalExtra();
      this.calculateTotalOs();
      this.calculateTotalLiquid();
      this.calculateTotalPart();
      this.calculateTotalWage();
      this.calculateTotal();
    },
    totalOs(os) {
      let totalPrice = parseFloat(os.pricePerOne) * parseFloat(os.amount); //มูลค่า
      if (!isNaN(totalPrice)) {
        //ไม่ให้เป็น NaN
        os.totalPrice = totalPrice.toFixed(2);
      }

      var dcPrice = 0;
      if (this.os.per) {
        dcPrice = parseFloat(os.totalPrice) * parseFloat(os.dcPer / 100); //ส่วนลด
        if (!isNaN(dcPrice)) {
          //ไม่ให้เป็น NaN
          os.dcPrice = dcPrice.toFixed(2);
        }
      } else if (this.os.bath) {
        os.dcPrice = os.dcPer;
      } else {
        os.dcPrice = os.dcPer;
      }

      let netPrice = parseFloat(os.totalPrice) - parseFloat(os.dcPrice); //สุทธิ(ลดแล้ว)
      if (!isNaN(netPrice)) {
        //ไม่ให้เป็น NaN
        os.netPrice = netPrice.toFixed(2);
      }
      this.calculateTotalExtra();
      this.calculateTotalOs();
      this.calculateTotalLiquid();
      this.calculateTotalPart();
      this.calculateTotalWage();
      this.calculateTotal();
    },
    totalExtra(extra) {
      let totalPrice = parseFloat(extra.pricePerOne) * parseFloat(extra.amount); //มูลค่า
      if (!isNaN(totalPrice)) {
        //ไม่ให้เป็น NaN
        extra.totalPrice = totalPrice.toFixed(2);
      }

      var dcPrice = 0;
      if (this.extra.per) {
        dcPrice = parseFloat(extra.totalPrice) * parseFloat(extra.dcPer / 100); //ส่วนลด
        if (!isNaN(dcPrice)) {
          //ไม่ให้เป็น NaN
          extra.dcPrice = dcPrice.toFixed(2);
        }
      } else if (this.extra.bath) {
        extra.dcPrice = extra.dcPer;
      } else {
        extra.dcPrice = extra.dcPer;
      }

      let netPrice = parseFloat(extra.totalPrice) - parseFloat(extra.dcPrice); //สุทธิ(ลดแล้ว)
      if (!isNaN(netPrice)) {
        //ไม่ให้เป็น NaN
        extra.netPrice = netPrice.toFixed(2);
      }
      this.calculateTotalExtra();
      this.calculateTotalOs();
      this.calculateTotalLiquid();
      this.calculateTotalPart();
      this.calculateTotalWage();
      this.calculateTotal();
    },
    deleteWpInvoice: function(rowsWp) {
      this.loading = true;
      useNetw
        .delete("api/tax-invoice/detail/delete", {
          data: {
            taxInvDetailId: rowsWp.taxInvDetailId,
            taxInvTotalPrice: this.wpSelect.totalPrice,
            taxInvDcPer: this.dcPer,
            taxInvDcPrice: this.wpSelect.dcPrice,
            taxInvNetPrice: this.wpSelect.netPrice,
            taxInvVatPer: this.vatPer,
            taxInvVatPrice: this.wpSelect.vatPrice,
            taxInvGrandTotalPrice: this.wpSelect.grandTotalPrice,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataWagePart();
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getWage: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/quotation/branch-wage", {
          params: {
            page: this.currentPage,
            perPage: 100,
            "branchId[]": this.selectedBranch.branchId,
            // selectWage:this.filter.selectWage,
            // selectWage: this.wages.wageId,
          },
        })
        .then((response) => {
          this.wageOptions = response.data.data;
          this.wageId = response.data.wageId;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getParts: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/quotation/branch-part", {
          params: {
            page: this.currentPage,
            perPage: 100,
            "branchId[]": this.selectedBranch.branchId,
            // selectWage:this.filter.selectWage,
            // selectWage: this.wages.wageId,
          },
        })
        .then((response) => {
          this.rowParts = response.data.data;
          // this.partId = response.data.partId;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getOs: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/quotation/branch-os", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            "branchId[]": this.selectedBranch.branchId,
          },
        })
        .then((response) => {
          this.rowOs = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getLiquid: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/quotation/branch-liquid", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            "branchId[]": this.selectedBranch.branchId,
          },
        })
        .then((response) => {
          this.rowLiquid = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getExtra: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/quotation/branch-extra", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            "branchId[]": this.selectedBranch.branchId,
          },
        })
        .then((response) => {
          this.rowExtra = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alertWage(rowRoWages, index) {
      Swal.fire({
        title: "ต้องการลบค่าแรงใช่หรือไม่",
        icon: "warning",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteRoWage(rowRoWages, index);
        }
      });
    },
    alertPart(rowRoParts, index) {
      Swal.fire({
        title: "Are you sure?",
        text: "ต้องการลบค่าอะไหล่ใช่หรือไม่",
        icon: "warning",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteRoPart(rowRoParts, index);
        }
      });
    },
    alertLiquid(rowRoLiquid, index) {
      Swal.fire({
        title: "Are you sure?",
        text: "ต้องการลบใช่หรือไม่",
        icon: "warning",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteRoLiquid(rowRoLiquid, index);
        }
      });
    },
    alertOs(rowRoOs, index) {
      Swal.fire({
        title: "Are you sure?",
        text: "ต้องการลบใช่หรือไม่",
        icon: "warning",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteRoOs(rowRoOs, index);
        }
      });
    },
    alertExtra(rowRoExtra, index) {
      Swal.fire({
        title: "Are you sure?",
        text: "ต้องการลบใช่หรือไม่",
        icon: "warning",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteRoExtra(rowRoExtra, index);
        }
      });
    },
    deleteRoWage: function(rowRoWages, index) {
      this.rowRoWages.splice(index, 1);
      this.totalWage(rowRoWages);
      // this.calculateTotal(this.rowRoWages);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/quotation/detail/delete", {
          data: {
            qndId: rowRoWages.qndId,
            qnWageTotalPrice: this.roWageTotalPrice,
            qnWageDcPer: this.roWageDcPer,
            qnWageDcPrice: this.roWageDcPrice,
            qnWageNetPrice: this.roWagePrice,
            qnPartTotalPrice: this.roPartTotalPrice,
            qnPartDcPer: this.roPartDcPer,
            qnPartDcPrice: this.roPartDcPrice,
            qnPartNetPrice: this.roPartNetPrice,
            qnOsTotalPrice: this.roOsTotalPrice,
            qnOsDcPer: this.roOsDcPer,
            qnOsDcPrice: this.roOsDcPrice,
            qnOsNetPrice: this.roOsNetPrice,
            qnLiquidTotalPrice: this.roLiquidTotalPrice,
            qnLiquidDcPer: this.roLiquidDcPer,
            qnLiquidDcPrice: this.roLiquidDcPrice,
            qnLiquidNetPrice: this.roLiquidNetPrice,
            qnExtraTotalPrice: this.roExtraTotalPrice,
            qnExtraDcPer: this.roExtraDcPer,
            qnExtraDcPrice: this.roExtraDcPrice,
            qnExtraNetPrice: this.roExtraNetPrice,
            qnTotalPrice: this.roTotalPrice,
            qnDcPer: this.roDcPer,
            qnDcPrice: this.roDcPrice,
            qnNetPrice: this.roNetPrice,
            qnVatPer: this.roVatPer,
            qnVatPrice: this.roVatPrice,
            qnGrandTotalPrice: this.roGrandTotalPrice,
            qnWhtPer: this.roWhtPer.name != null ? this.roWhtPer.name : 0,
            qnWhtPrice: this.roWhtPrice,
            qnPaymentAmount: this.roPaymentAmount,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false; //skeleton false
        });
    },
    deleteRoPart: function(rowRoParts, index) {
      this.rowRoParts.splice(index, 1);
      this.totalPart(rowRoParts);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/quotation/detail/delete", {
          data: {
            qndId: rowRoParts.qndId,
            qnWageTotalPrice: this.roWageTotalPrice,
            qnWageDcPer: this.roWageDcPer,
            qnWageDcPrice: this.roWageDcPrice,
            qnWageNetPrice: this.roWagePrice,
            qnPartTotalPrice: this.roPartTotalPrice,
            qnPartDcPer: this.roPartDcPer,
            qnPartDcPrice: this.roPartDcPrice,
            qnPartNetPrice: this.roPartNetPrice,
            qnOsTotalPrice: this.roOsTotalPrice,
            qnOsDcPer: this.roOsDcPer,
            qnOsDcPrice: this.roOsDcPrice,
            qnOsNetPrice: this.roOsNetPrice,
            qnLiquidTotalPrice: this.roLiquidTotalPrice,
            qnLiquidDcPer: this.roLiquidDcPer,
            qnLiquidDcPrice: this.roLiquidDcPrice,
            qnLiquidNetPrice: this.roLiquidNetPrice,
            qnExtraTotalPrice: this.roExtraTotalPrice,
            qnExtraDcPer: this.roExtraDcPer,
            qnExtraDcPrice: this.roExtraDcPrice,
            qnExtraNetPrice: this.roExtraNetPrice,
            qnTotalPrice: this.roTotalPrice,
            qnDcPer: this.roDcPer,
            qnDcPrice: this.roDcPrice,
            qnNetPrice: this.roNetPrice,
            qnVatPer: this.roVatPer,
            qnVatPrice: this.roVatPrice,
            qnGrandTotalPrice: this.roGrandTotalPrice,
            qnWhtPer: this.roWhtPer.name != null ? this.roWhtPer.name : 0,
            qnWhtPrice: this.roWhtPrice,
            qnPaymentAmount: this.roPaymentAmount,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false; //skeleton false
        });
    },
    deleteRoLiquid: function(rowRoLiquid, index) {
      this.rowRoLiquid.splice(index, 1);
      this.totalLiquid(rowRoLiquid);

      this.loading = true; //skeleton true
      useNetw
        .delete("api/quotation/detail/delete", {
          data: {
            qndId: rowRoLiquid.qndId,
            qnWageTotalPrice: this.roWageTotalPrice,
            qnWageDcPer: this.roWageDcPer,
            qnWageDcPrice: this.roWageDcPrice,
            qnWageNetPrice: this.roWagePrice,
            qnPartTotalPrice: this.roPartTotalPrice,
            qnPartDcPer: this.roPartDcPer,
            qnPartDcPrice: this.roPartDcPrice,
            qnPartNetPrice: this.roPartNetPrice,
            qnOsTotalPrice: this.roOsTotalPrice,
            qnOsDcPer: this.roOsDcPer,
            qnOsDcPrice: this.roOsDcPrice,
            qnOsNetPrice: this.roOsNetPrice,
            qnLiquidTotalPrice: this.roLiquidTotalPrice,
            qnLiquidDcPer: this.roLiquidDcPer,
            qnLiquidDcPrice: this.roLiquidDcPrice,
            qnLiquidNetPrice: this.roLiquidNetPrice,
            qnExtraTotalPrice: this.roExtraTotalPrice,
            qnExtraDcPer: this.roExtraDcPer,
            qnExtraDcPrice: this.roExtraDcPrice,
            qnExtraNetPrice: this.roExtraNetPrice,
            qnTotalPrice: this.roTotalPrice,
            qnDcPer: this.roDcPer,
            qnDcPrice: this.roDcPrice,
            qnNetPrice: this.roNetPrice,
            qnVatPer: this.roVatPer,
            qnVatPrice: this.roVatPrice,
            qnGrandTotalPrice: this.roGrandTotalPrice,
            qnWhtPer: this.roWhtPer.name != null ? this.roWhtPer.name : 0,
            qnWhtPrice: this.roWhtPrice,
            qnPaymentAmount: this.roPaymentAmount,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false; //skeleton false
        });
    },
    deleteRoOs: function(rowRoOs, index) {
      this.rowRoOs.splice(index, 1);
      this.totalOs(rowRoOs);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/quotation/detail/delete", {
          data: {
            qndId: rowRoOs.qndId,
            qnWageTotalPrice: this.roWageTotalPrice,
            qnWageDcPer: this.roWageDcPer,
            qnWageDcPrice: this.roWageDcPrice,
            qnWageNetPrice: this.roWagePrice,
            qnPartTotalPrice: this.roPartTotalPrice,
            qnPartDcPer: this.roPartDcPer,
            qnPartDcPrice: this.roPartDcPrice,
            qnPartNetPrice: this.roPartNetPrice,
            qnOsTotalPrice: this.roOsTotalPrice,
            qnOsDcPer: this.roOsDcPer,
            qnOsDcPrice: this.roOsDcPrice,
            qnOsNetPrice: this.roOsNetPrice,
            qnLiquidTotalPrice: this.roLiquidTotalPrice,
            qnLiquidDcPer: this.roLiquidDcPer,
            qnLiquidDcPrice: this.roLiquidDcPrice,
            qnLiquidNetPrice: this.roLiquidNetPrice,
            qnExtraTotalPrice: this.roExtraTotalPrice,
            qnExtraDcPer: this.roExtraDcPer,
            qnExtraDcPrice: this.roExtraDcPrice,
            qnExtraNetPrice: this.roExtraNetPrice,
            qnTotalPrice: this.roTotalPrice,
            qnDcPer: this.roDcPer,
            qnDcPrice: this.roDcPrice,
            qnNetPrice: this.roNetPrice,
            qnVatPer: this.roVatPer,
            qnVatPrice: this.roVatPrice,
            qnGrandTotalPrice: this.roGrandTotalPrice,
            qnWhtPer: this.roWhtPer.name != null ? this.roWhtPer.name : 0,
            qnWhtPrice: this.roWhtPrice,
            qnPaymentAmount: this.roPaymentAmount,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false; //skeleton false
        });
    },
    deleteRoExtra: function(rowRoExtra, index) {
      this.rowRoExtra.splice(index, 1);
      this.totalExtra(rowRoExtra);
      // this.calculateTotal(this.rowRoExtra);

      this.loading = true; //skeleton true
      useNetw
        .delete("api/quotation/detail/delete", {
          data: {
            qndId: rowRoExtra.qndId,
            qnWageTotalPrice: this.roWageTotalPrice,
            qnWageDcPer: this.roWageDcPer,
            qnWageDcPrice: this.roWageDcPrice,
            qnWageNetPrice: this.roWagePrice,
            qnPartTotalPrice: this.roPartTotalPrice,
            qnPartDcPer: this.roPartDcPer,
            qnPartDcPrice: this.roPartDcPrice,
            qnPartNetPrice: this.roPartNetPrice,
            qnOsTotalPrice: this.roOsTotalPrice,
            qnOsDcPer: this.roOsDcPer,
            qnOsDcPrice: this.roOsDcPrice,
            qnOsNetPrice: this.roOsNetPrice,
            qnLiquidTotalPrice: this.roLiquidTotalPrice,
            qnLiquidDcPer: this.roLiquidDcPer,
            qnLiquidDcPrice: this.roLiquidDcPrice,
            qnLiquidNetPrice: this.roLiquidNetPrice,
            qnExtraTotalPrice: this.roExtraTotalPrice,
            qnExtraDcPer: this.roExtraDcPer,
            qnExtraDcPrice: this.roExtraDcPrice,
            qnExtraNetPrice: this.roExtraNetPrice,
            qnTotalPrice: this.roTotalPrice,
            qnDcPer: this.roDcPer,
            qnDcPrice: this.roDcPrice,
            qnNetPrice: this.roNetPrice,
            qnVatPer: this.roVatPer,
            qnVatPrice: this.roVatPrice,
            qnGrandTotalPrice: this.roGrandTotalPrice,
            qnWhtPer: this.roWhtPer.name != null ? this.roWhtPer.name : 0,
            qnWhtPrice: this.roWhtPrice,
            qnPaymentAmount: this.roPaymentAmount,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false; //skeleton false
        });
    },
    // getRoWage: function () {
    //   this.loading = true; //skeleton true
    //   useNetw
    //     .get("api/quotation/branch-wage", {
    //       params: {
    //         page: this.currentPage,
    //         perPage: this.perPage,
    //         "branchId[]": this.selectedBranch.branchId
    //       },
    //     })
    //     .then((response) => {
    //       this.rowRoWages = response.data.data;
    //       this.rowRoWages.forEach((element, index) => {
    //         // เข้าถึง array
    //         this.rowRoWages[index].selectWage = {
    //           wageId: element.wageId,
    //           nameTh: element.wageNameTh,
    //         };
    //       });
    //     })
    //     .catch((err) => {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(err.message),
    //           appConfig.swal.type.error
    //         );
    //     })
    //     .then(() => {
    //       // this.overlayFlag = false;
    //       this.loading = false; //skeleton false
    //     });
    // },
  },
  middleware: "authentication",
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}

.con-nav-footer {
  padding-left: 20rem;
}
.part {
  width: 240px;
}
.wage {
  width: 200px;
}
.number {
  width: 100px;
  text-align: right;
}
.Dc {
  width: 80px;
}
.action {
  width: 40px;
}
.numberP {
  width: 80px;
  text-align: right;
}
.price {
  width: 100px;
  text-align: right;
}

// .con-nav-footer {
//   padding-left: 20rem;
// }
// .part {
//   width: 240px;
// }
// .wage {
//   width: auto;
// }
// .number {
//   width: 140px;
//   text-align: right;
// }
// .Dc {
//   width: 100px;
// }
// .action {
//   width: 40px;
// }
// .numberP {
//   width: 140px;
//   text-align: right;
// }
// .price {
//   width: 170px;
//   text-align: right;
// }
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-alert
          class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
          :show="dismissCountDown"
          dismissible
          fade
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p class="float-end">{{ dismissCountDown }} วินาที...</p>
          <p>แจ้งเตือน</p>
          <hr />
          <p>{{ resalert }}</p>
          <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
        </b-alert>
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-4 col-md-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>สาขา:
                        <multiselect
                          v-model="selectedBranch"
                          label="nameTh"
                          :options="branchMaster"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          @input="onRowSelectedRo"
                          :class="{
                            'is-invalid':
                              submitform && $v.selectedBranch.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.selectedBranch.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.selectedBranch.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-8 col-sm-3 col-md-3">
                      <div class="mb-3">
                        เลขใบเข้ารับบริการ:
                        <multiselect
                          v-model="selectSV"
                          label="svCode"
                          :options="rowsSv"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-1 col-sm-1 col-md-1">
                      <div class="mt-3 position-relative">
                        <b-button
                          class="btn"
                          variant="info"
                          v-b-modal.modalCreateTaxInvoice
                        >
                          <i class="uil-file-search-alt"></i
                        ></b-button>
                      </div>
                    </div>
                    <div class="col-8 col-sm-3 col-md-3">
                      <div class="mb-3">
                        เลขใบแจ้งซ่อม:
                        <multiselect
                          v-model="selectRO"
                          label="roCode"
                          :options="rowsRo"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-1 col-sm-1 col-md-1">
                      <div class="mt-3 position-relative">
                        <b-button
                          class="btn"
                          variant="info"
                          v-b-modal.modalCreateTaxInvoice
                        >
                          <i class="uil-file-search-alt"></i
                        ></b-button>
                      </div>
                    </div>
                    <div class="col-7 col-sm-6 col-md-3">
                      <div class="mb-3">
                        ชื่อลูกค้า:
                        <multiselect
                          v-model="cusName"
                          label="nameTh"
                          :options="rowsCustomer"
                          :custom-label="customLabel"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-8 col-sm-6 col-md-3">
                      <div class="mb-3">
                        ป้ายทะเบียน:
                        <multiselect
                          v-model="vehicle"
                          label="licensePlate"
                          :options="rowsVehicle"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-7 col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code>วันที่ออกใบเสนอราคา:
                        <date-picker
                          v-model="qnDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          :class="{
                            'is-invalid': submitform && $v.qnDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.qnDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.qnDate.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                      <label class="form-label d-block mb-3"
                        ><code> * </code>ประเภทเข้ารับบริการ</label
                      >
                      <div class="custom-radio form-check form-check-inline">
                        <b-form-radio
                          id="isWash"
                          name="outer-group[0][isType]"
                          v-model="qnDept"
                          class="mb-3"
                          value="GS"
                          unchecked-value=""
                          plain
                          >GS</b-form-radio
                        >
                      </div>
                      <div class="custom-radio form-check form-check-inline">
                        <b-form-radio
                          id="isType2"
                          name="outer-group[0][isType]"
                          v-model="qnDept"
                          class="mb-3"
                          value="BP"
                          unchecked-value=""
                          plain
                          >BP</b-form-radio
                        >
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code>ประเภทใบเสนอราคา:
                        <multiselect
                          v-model="qnTypeId"
                          label="nameTh"
                          :options="qnType"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.qnTypeId.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.qnTypeId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.qnTypeId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                      <div class="mb-3">
                        หมายเหตุ:
                        <b-form-textarea
                          v-model="note"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-12 col-sm-12 col-md-7">
                  <div class="row">
                    <div class="col-5 col-sm-5 col-md-5">
                      <div class="mb-3 position-relative">
                        <code> * </code>สาขา:
                        <multiselect
                          v-model="selectedBranch"
                          label="nameTh"
                          :options="branchMaster"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          @input="onRowSelectedRo"
                          :class="{
                            'is-invalid':
                              submitform && $v.selectedBranch.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.selectedBranch.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.selectedBranch.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-5 col-sm-5 col-md-5">
                      <div class="mb-3">
                        เลขใบเข้ารับบริการ:
                        <multiselect
                          v-model="selectSV"
                          label="svCode"
                          :options="rowsSv"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2">
                      <div class="mt-3 position-relative">
                        <b-button
                          class="btn"
                          variant="info"
                          v-b-modal.modalCreateTaxInvoice
                        >
                          <i class="uil-file-search-alt"></i
                        ></b-button>
                      </div>
                    </div>
                    <div class="col-5 col-sm-5 col-md-5">
                      <div class="mb-3">
                        เลขใบแจ้งซ่อม:
                        <multiselect
                          v-model="selectRO"
                          label="roCode"
                          :options="rowsRo"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2">
                      <div class="mt-3 position-relative">
                        <b-button
                          class="btn"
                          variant="info"
                          v-b-modal.modalCreateTaxInvoice
                        >
                          <i class="uil-file-search-alt"></i
                        ></b-button>
                      </div>
                    </div>
                    <div class="col-7 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ชื่อลูกค้า:
                        <multiselect
                          v-model="cusName"
                          label="nameTh"
                          :options="rowsCustomer"
                          :custom-label="customLabel"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ป้ายทะเบียน:
                        <multiselect
                          v-model="vehicle"
                          label="licensePlate"
                          :options="rowsVehicle"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>วันที่ออกใบเสนอราคา:
                        <date-picker
                          v-model="qnDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          :class="{
                            'is-invalid': submitform && $v.qnDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.qnDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.qnDate.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 col-sm-6 col-md-4">
                      <label class="form-label d-block mb-3"
                        ><code> * </code>ประเภทเข้ารับบริการ</label
                      >
                      <div class="custom-radio form-check form-check-inline">
                        <b-form-radio
                          id="isWash"
                          name="outer-group[0][isType]"
                          v-model="qnDept"
                          class="mb-3"
                          value="GS"
                          unchecked-value=""
                          plain
                          >GS</b-form-radio
                        >
                      </div>
                      <div class="custom-radio form-check form-check-inline">
                        <b-form-radio
                          id="isType2"
                          name="outer-group[0][isType]"
                          v-model="qnDept"
                          class="mb-3"
                          value="BP"
                          unchecked-value=""
                          plain
                          >BP</b-form-radio
                        >
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>ประเภทใบเสนอราคา:
                        <multiselect
                          v-model="qnTypeId"
                          label="nameTh"
                          :options="qnType"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.qnTypeId.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.qnTypeId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.qnTypeId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                      <div class="mb-3">
                        หมายเหตุ:
                        <b-form-textarea
                          v-model="note"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="col-12 col-sm-12 col-md-5">
                  <div class="row mb-4">
                    <div class="col-12 d-flex justify-content-end">
                      <div
                        class="col-12 col-sm-12 col-md-12 bg-white m-0 text-end"
                        style="border-radius:10px;
                            margin:auto;
                            justify "
                      >
                        <div class="row align-items-center m-0">
                          <div class="col-12">
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ราคารวม :
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="totalPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ส่วนลด (%):
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="dcPer"
                                  class="form-control text-end"
                                  disabled
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                (%)
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ส่วนลด (บาท) :
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="dcPrice"
                                  class="form-control text-end"
                                  disabled
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                สุทธิ :
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="netPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ภาษี (%):
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="vatPer"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                (%)
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ภาษี (บาท):
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="vatPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2 mb-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                มูลค่ารวม :
                              </div>
                              <div class="col-6 font-size-24">
                                <b-form-input
                                  v-model="grandTotalPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 mb-3">
                  <b-button
                    class="btn float-end m-1"
                    variant="danger"
                    type="submit"
                    @click="tooltipForm"
                  >
                    <!-- <i class="uil-search-alt"></i> -->
                    เปิดใบเสนอราคา
                  </b-button>
                  <b-button
                    class="btn float-end m-1"
                    variant="info"
                    type="submit"
                    @click="updateShow"
                  >
                    <!-- <i class="uil-search-alt"></i> -->
                    บันทึกการแก้ไขข้อมูล
                  </b-button>
                </div>
              </div>

              <h4>รายละเอียดเพิ่มเติม</h4>
              <hr />
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        SRT:
                        <b-form-input type="text" v-model="srt"></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                      <label class="form-label d-block mb-3"
                        >ลูกค้าตัดสินใจ</label
                      >
                      <div class="custom-radio form-check-inline">
                        <b-form-radio
                          id="issWash"
                          name="out-group[0][isType]"
                          v-model="approved"
                          class="mb-3"
                          value="1"
                          unchecked-value="1"
                          plain
                          >อนุมัติ</b-form-radio
                        >
                      </div>
                      <div class="custom-radio form-check-inline">
                        <b-form-radio
                          id="issType2"
                          name="out-group[0][isType]"
                          v-model="approved"
                          class="mb-3"
                          value="0"
                          unchecked-value="0"
                          plain
                          >ไม่อนุมัติ</b-form-radio
                        >
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-5">
                      <div class="mb-3">
                        รายละเอียด:
                        <b-form-textarea
                          v-model="noteDesc"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 mb-3">
                  <b-button
                    class="btn float-end m-1"
                    variant="info"
                    type="submit"
                    @click="submitDesc"
                  >
                    <!-- <i class="uil-search-alt"></i> -->
                    บันทึกรายละเอียด
                  </b-button>
                </div>
              </div>

              <b-tabs
                content-class="p-4"
                justified
                class="nav-tabs-custom"
                v-if="this.$route.params.qnId"
              >
                <!-- ค่าแรง -->
                <b-tab active>
                  <template v-slot:title>
                    <span class="d-none d-sm-block">ค่าแรง</span>
                  </template>
                  <div class="p-4 border-top">
                    <form class="needs-validation" @submit.prevent="wageArray">
                      <div class="row p-4">
                        <div class="col-md-6 col-sm-6">
                          <strong><code>* </code>ประเภทส่วนลด</strong>

                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="wage.bath"
                              id="isWash"
                              name="outer-group[0][isWash]"
                              :options="optionTypeDc"
                              class="mb-3"
                              value-field="item"
                              text-field="name"
                              @input="getRoWage()"
                              plain
                              >บาท</b-form-radio
                            >
                          </div>
                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="wage.per"
                              id="isWash2"
                              :options="optionTypeDc"
                              name="outer-group[0][isWash]"
                              class="mb-3"
                              value-field="item"
                              text-field="name"
                              @input="getRoWage()"
                              plain
                              >เปอร์เซ็น</b-form-radio
                            >
                          </div>
                        </div>
                      </div>
                      <div>
                        <span
                          >รายการค่าแรง
                          <span class="badge bg-info font-size-12 ms-2"
                            >{{ this.rowRoWages.length }}
                          </span>
                          รายการ</span
                        >
                      </div>
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>ค่าแรง</th>
                              <th style="text-align: center">ราคา/หน่วย</th>
                              <th>ชั่วโมงงาน</th>
                              <th style="text-align: center">มูลค่า</th>
                              <th style="text-align: center">ส่วนลด</th>
                              <th style="text-align: center">ส่วนลดยอด(บาท)</th>
                              <th style="text-align: center">สุทธิ</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(wageItem, index) in rowRoWages"
                              :key="index"
                            >
                              <td>{{ index + 1 }}</td>
                              <td scope="row">
                                <multiselect
                                  class="wage"
                                  type="search"
                                  :options="wageOptions"
                                  v-model="wageItem.selectWage"
                                  :loading="loading"
                                  :close-on-select="true"
                                  :internal-search="true"
                                  open-direction="bottom"
                                  @search-change="getWage()"
                                  @input="
                                    upsertWage(wageItem.selectWage, wageItem)
                                  "
                                  :show-labels="false"
                                  label="nameTh"
                                  placeholder=""
                                  :class="{
                                    'is-invalid':
                                      form && $v.wageItem.wageId.$error,
                                  }"
                                >
                                  <span slot="noResult">ไม่พบข้อมูล</span>

                                  <div
                                    v-if="form && $v.wageItem.wageId.$error"
                                    class="invalid-tooltip"
                                  >
                                    <span v-if="!$v.wageItem.wageId.required">{{
                                      message
                                    }}</span>
                                  </div>
                                </multiselect>
                              </td>
                              <td class="">
                                <input
                                  min="0"
                                  v-model="wageItem.pricePerOne"
                                  class="form-control text-end number"
                                  @input="totalWage(wageItem)"
                                  @change="totalWage(wageItem)"
                                  @keyup.enter="
                                    updateRoWage(
                                      wageItem.wageId,
                                      wageItem.pricePerOne,
                                      wageItem.amount,
                                      wageItem.totalPrice,
                                      wageItem.dcPer,
                                      wageItem.dcPrice,
                                      wageItem.netPrice,
                                      wageItem
                                    )
                                  "
                                  :class="{
                                    'is-invalid':
                                      form && $v.wageItem.pricePerOne.$error,
                                  }"
                                />
                                <div
                                  v-if="form && $v.wageItem.pricePerOne.$error"
                                  class="invalid-tooltip"
                                >
                                  <span
                                    v-if="!$v.wageItem.pricePerOne.required"
                                    >{{ message }}</span
                                  >
                                </div>
                              </td>
                              <td class="">
                                <input
                                  type="number"
                                  @keyup.enter="
                                    updateRoWage(
                                      wageItem.wageId,
                                      wageItem.pricePerOne,
                                      wageItem.amount,
                                      wageItem.totalPrice,
                                      wageItem.dcPer,
                                      wageItem.dcPrice,
                                      wageItem.netPrice,
                                      wageItem
                                    )
                                  "
                                  min="0"
                                  v-model="wageItem.amount"
                                  @input="totalWage(wageItem)"
                                  @change="totalWage(wageItem)"
                                  class="form-control text-end Dc"
                                  :class="{
                                    'is-invalid':
                                      form && $v.wageItem.amount.$error,
                                  }"
                                />
                                <div
                                  v-if="form && $v.wageItem.amount.$error"
                                  class="invalid-tooltip"
                                >
                                  <span v-if="!$v.wageItem.amount.required">{{
                                    message
                                  }}</span>
                                </div>
                              </td>
                              <td class="" style="text-align: center">
                                <input
                                  disabled
                                  v-model="wageItem.totalPrice"
                                  class="form-control text-end number"
                                />
                              </td>
                              <td class="">
                                <input
                                  min="0"
                                  v-model="wageItem.dcPer"
                                  class="form-control text-end Dc"
                                  @input="totalWage(wageItem)"
                                  @change="totalWage(wageItem)"
                                  @keyup.enter="
                                    updateRoWage(
                                      wageItem.wageId,
                                      wageItem.pricePerOne,
                                      wageItem.amount,
                                      wageItem.totalPrice,
                                      wageItem.dcPer,
                                      wageItem.dcPrice,
                                      wageItem.netPrice,
                                      wageItem
                                    )
                                  "
                                  append=".00"
                                  :class="{
                                    'is-invalid':
                                      form && $v.wageItem.dcPer.$error,
                                  }"
                                />
                                <div
                                  v-if="form && $v.wageItem.dcPer.$error"
                                  class="invalid-tooltip"
                                >
                                  <span v-if="!$v.wageItem.dcPer.required">{{
                                    message
                                  }}</span>
                                </div>
                              </td>
                              <td class="" style="text-align: center">
                                <input
                                  disabled
                                  v-model="wageItem.dcPrice"
                                  class="form-control text-end number"
                                />
                              </td>

                              <td class="" style="text-align: center">
                                <input
                                  disabled
                                  v-model="wageItem.netPrice"
                                  class="form-control text-end number"
                                />
                              </td>
                              <td>
                                <ul class="list-inline mb-0 action">
                                  <li class="list-inline-item">
                                    <a
                                      class="px-2 text-danger"
                                      v-b-tooltip.hover
                                      title="Delete"
                                      @click="alertWage(wageItem, index)"
                                    >
                                      <i
                                        class="uil uil-trash-alt font-size-18"
                                      ></i>
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" colspan="6" class="text-end">
                                มูลค่ารวม :
                              </th>
                              <td class="text-end">
                                {{
                                  Number(this.roWageTotalPrice).toLocaleString()
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                ส่วนลดรวม :
                              </th>
                              <td class="border-0 text-end">
                                {{ this.roWageDcPer }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                ส่วนลดยอดรวม(บาท) :
                              </th>
                              <td class="border-0 text-end">
                                {{
                                  Number(this.roWageDcPrice).toLocaleString()
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                สุทธิรวม :
                              </th>
                              <td class="border-0 text-end">
                                {{ Number(this.roWagePrice).toLocaleString() }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="row p-4">
                          <div class="col-md-6 ml-4">
                            <button
                              type="button"
                              class="btn btn-success btn-sm"
                              @click="addformWage()"
                            >
                              <i class="mdi mdi-plus me-1"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </b-tab>
                <!-- ค่าอะไหล่ -->
                <b-tab>
                  <template v-slot:title>
                    <span class="d-none d-sm-block">ค่าอะไหล่</span>
                  </template>
                  <div class="p-4 border-top">
                    <form class="needs-validation" @submit.prevent="formDis">
                      <div class="row p-4">
                        <div class="col-md-6 col-sm-6">
                          <strong><code>* </code>ประเภทส่วนลด</strong>

                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="part.bath"
                              id="isWash"
                              name="outer-group[0][isWash]"
                              class="mb-3"
                              value="1"
                              unchecked-value="0"
                              @input="getRoPart()"
                              plain
                              >บาท</b-form-radio
                            >
                          </div>
                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="part.per"
                              id="isWash2"
                              name="outer-group[0][isWash]"
                              class="mb-3"
                              value="2"
                              unchecked-value="0"
                              @input="getRoPart()"
                              plain
                              >เปอร์เซ็น</b-form-radio
                            >
                          </div>
                        </div>
                      </div>

                      <span
                        >รายการอะไหล่
                        <span class="badge bg-info font-size-12 ms-2"
                          >{{ this.rowRoParts.length }}
                        </span>
                        รายการ</span
                      >
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>ค่าอะไหล่</th>
                              <th style="text-align: center">ราคา/หน่วย</th>
                              <th style="text-align: center">ชิ้น</th>
                              <th style="text-align: center">มูลค่า</th>
                              <th style="text-align: center">ส่วนลด</th>
                              <th style="text-align: center">ส่วนลดยอด(บาท)</th>
                              <th style="text-align: center">สุทธิ</th>
                              <th style="text-align: center"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(partItem, index) in rowRoParts"
                              :key="index"
                            >
                              <!-- @search-change="getWage" -->
                              <td>{{ index + 1 }}</td>
                              <td scope="row">
                                <multiselect
                                  class="part"
                                  type="search"
                                  :options="rowParts"
                                  v-model="partItem.selectPart"
                                  :loading="loading"
                                  :close-on-select="true"
                                  :internal-search="true"
                                  open-direction="bottom"
                                  @search-change="getParts()"
                                  @input="
                                    upsertPart(partItem.selectPart, partItem)
                                  "
                                  :show-labels="false"
                                  label="nameTh"
                                  placeholder=""
                                >
                                  <span slot="noResult">ไม่พบข้อมูล</span>
                                </multiselect>
                              </td>

                              <td class="">
                                <input
                                  min="0"
                                  v-model="partItem.pricePerOne"
                                  class="form-control text-end price"
                                  @change="totalPart(partItem)"
                                  @input="totalPart(partItem)"
                                  @keyup.enter="
                                    updateRoPart(
                                      partItem.partId,
                                      partItem.pricePerOne,
                                      partItem.amount,
                                      partItem.totalPrice,
                                      partItem.dcPer,
                                      partItem.dcPrice,
                                      partItem.netPrice,
                                      partItem
                                    )
                                  "
                                />
                              </td>
                              <td class="">
                                <input
                                  type="number"
                                  min="0"
                                  v-model="partItem.amount"
                                  class="form-control text-end numberP"
                                  @keyup.enter="
                                    updateRoPart(
                                      partItem.partId,
                                      partItem.pricePerOne,
                                      partItem.amount,
                                      partItem.totalPrice,
                                      partItem.dcPer,
                                      partItem.dcPrice,
                                      partItem.netPrice,
                                      partItem
                                    )
                                  "
                                  @change="totalPart(partItem)"
                                  @input="totalPart(partItem)"
                                />
                              </td>
                              <td class="">
                                <input
                                  disabled
                                  v-model="partItem.totalPrice"
                                  class="form-control text-end price"
                                />
                              </td>
                              <td class="">
                                <input
                                  min="0"
                                  v-model="partItem.dcPer"
                                  @change="totalPart(partItem)"
                                  @input="totalPart(partItem)"
                                  @keyup.enter="
                                    updateRoPart(
                                      partItem.partId,
                                      partItem.pricePerOne,
                                      partItem.amount,
                                      partItem.totalPrice,
                                      partItem.dcPer,
                                      partItem.dcPrice,
                                      partItem.netPrice,
                                      partItem
                                    )
                                  "
                                  class="form-control text-end numberP"
                                  append=".00"
                                />
                              </td>
                              <td class="">
                                <input
                                  disabled
                                  v-model="partItem.dcPrice"
                                  class="form-control text-end price"
                                />
                              </td>

                              <td>
                                <input
                                  disabled
                                  v-model="partItem.netPrice"
                                  class="form-control text-end price"
                                />
                              </td>
                              <td>
                                <ul class="list-inline mb-0 action">
                                  <li class="list-inline-item">
                                    <a
                                      class="px-2 text-danger"
                                      v-b-tooltip.hover
                                      title="Delete"
                                      @click="alertPart(partItem, index)"
                                    >
                                      <i
                                        class="uil uil-trash-alt font-size-18"
                                      ></i>
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>

                            <tr>
                              <th scope="row" colspan="6" class="text-end">
                                มูลค่ารวม :
                              </th>
                              <td class="text-end">
                                {{
                                  Number(this.roPartTotalPrice).toLocaleString()
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                ส่วนลดรวม :
                              </th>
                              <td class="border-0 text-end">
                                {{ this.roPartDcPer }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                ส่วนลดยอดรวม (บาท) :
                              </th>
                              <td class="border-0 text-end">
                                {{
                                  Number(this.roPartDcPrice).toLocaleString()
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                สุทธิรวม :
                              </th>
                              <td class="border-0 text-end">
                                {{
                                  Number(this.roPartNetPrice).toLocaleString()
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="row p-4">
                          <div class="col-md-6 ml-4">
                            <button
                              type="button"
                              class="btn btn-success btn-sm"
                              @click="addformPart()"
                            >
                              <i class="mdi mdi-plus me-1"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </b-tab>
                <!-- ของเหลว -->
                <b-tab>
                  <template v-slot:title>
                    <span class="d-none d-sm-block">ของเหลว</span>
                  </template>
                  <div class="p-4 border-top">
                    <form
                      class="needs-validation"
                      @submit.prevent="liquidArray"
                    >
                      <div class="row p-4">
                        <div class="col-md-6 col-sm-6">
                          <strong><code>* </code>ประเภทส่วนลด</strong>

                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="liquid.bath"
                              id="isWash"
                              name="outer-group[0][isWash]"
                              class="mb-3"
                              value="1"
                              unchecked-value="0"
                              @input="getRoLiquid()"
                              plain
                              >บาท</b-form-radio
                            >
                          </div>
                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="liquid.per"
                              id="isWash2"
                              name="outer-group[0][isWash]"
                              class="mb-3"
                              value="2"
                              unchecked-value="0"
                              @input="getRoLiquid()"
                              plain
                              >เปอร์เซ็น</b-form-radio
                            >
                          </div>
                        </div>
                      </div>
                      <span
                        >รายการของเหลว
                        <span class="badge bg-info font-size-12 ms-2"
                          >{{ this.rowRoLiquid.length }}
                        </span>
                        รายการ</span
                      >
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>ค่าของเหลว</th>
                              <th style="text-align: center">ราคา/หน่วย</th>
                              <th>จำนวน</th>
                              <th style="text-align: center">มูลค่า</th>
                              <th style="text-align: center">ส่วนลด</th>
                              <th style="text-align: center">ส่วนลดยอด(บาท)</th>
                              <th style="text-align: center">สุทธิ</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(liquidItem, index) in rowRoLiquid"
                              :key="index"
                            >
                              <td>{{ index + 1 }}</td>
                              <td scope="row">
                                <multiselect
                                  class="part"
                                  type="search"
                                  :options="rowLiquid"
                                  v-model="liquidItem.selectLiquid"
                                  :loading="loading"
                                  :close-on-select="true"
                                  :internal-search="true"
                                  open-direction="bottom"
                                  @search-change="getLiquid()"
                                  @input="
                                    upsertLiquid(
                                      liquidItem.selectLiquid,
                                      liquidItem
                                    )
                                  "
                                  :show-labels="false"
                                  label="nameTh"
                                  placeholder=""
                                  :class="{
                                    'is-invalid':
                                      form && $v.liquidItem.partId.$error,
                                  }"
                                >
                                  <span slot="noResult">ไม่พบข้อมูล</span>

                                  <div
                                    v-if="form && $v.liquidItem.partId.$error"
                                    class="invalid-tooltip"
                                  >
                                    <span
                                      v-if="!$v.liquidItem.partId.required"
                                      >{{ message }}</span
                                    >
                                  </div>
                                </multiselect>
                              </td>
                              <td class="">
                                <input
                                  min="0"
                                  v-model="liquidItem.pricePerOne"
                                  class="form-control text-end number"
                                  @input="totalLiquid(liquidItem)"
                                  @change="totalLiquid(liquidItem)"
                                  @keyup.enter="
                                    updateRoLiquid(
                                      liquidItem.partId,
                                      liquidItem.pricePerOne,
                                      liquidItem.amount,
                                      liquidItem.totalPrice,
                                      liquidItem.dcPer,
                                      liquidItem.dcPrice,
                                      liquidItem.netPrice,
                                      liquidItem
                                    )
                                  "
                                  :class="{
                                    'is-invalid':
                                      form && $v.liquidItem.pricePerOne.$error,
                                  }"
                                />
                                <div
                                  v-if="
                                    form && $v.liquidItem.pricePerOne.$error
                                  "
                                  class="invalid-tooltip"
                                >
                                  <span
                                    v-if="!$v.liquidItem.pricePerOne.required"
                                    >{{ message }}</span
                                  >
                                </div>
                              </td>
                              <td class="">
                                <input
                                  type="number"
                                  @keyup.enter="
                                    updateRoLiquid(
                                      liquidItem.partId,
                                      liquidItem.pricePerOne,
                                      liquidItem.amount,
                                      liquidItem.totalPrice,
                                      liquidItem.dcPer,
                                      liquidItem.dcPrice,
                                      liquidItem.netPrice,
                                      liquidItem
                                    )
                                  "
                                  min="0"
                                  v-model="liquidItem.amount"
                                  @input="totalLiquid(liquidItem)"
                                  @change="totalLiquid(liquidItem)"
                                  class="form-control text-end Dc"
                                  :class="{
                                    'is-invalid':
                                      form && $v.liquidItem.amount.$error,
                                  }"
                                />
                                <div
                                  v-if="form && $v.liquidItem.amount.$error"
                                  class="invalid-tooltip"
                                >
                                  <span v-if="!$v.liquidItem.amount.required">{{
                                    message
                                  }}</span>
                                </div>
                              </td>
                              <td class="" style="text-align: center">
                                <input
                                  disabled
                                  v-model="liquidItem.totalPrice"
                                  class="form-control text-end number"
                                />
                              </td>
                              <td class="">
                                <input
                                  min="0"
                                  v-model="liquidItem.dcPer"
                                  class="form-control text-end Dc"
                                  @input="totalLiquid(liquidItem)"
                                  @change="totalLiquid(liquidItem)"
                                  @keyup.enter="
                                    updateRoLiquid(
                                      liquidItem.partId,
                                      liquidItem.pricePerOne,
                                      liquidItem.amount,
                                      liquidItem.totalPrice,
                                      liquidItem.dcPer,
                                      liquidItem.dcPrice,
                                      liquidItem.netPrice,
                                      liquidItem
                                    )
                                  "
                                  append=".00"
                                  :class="{
                                    'is-invalid':
                                      form && $v.liquidItem.dcPer.$error,
                                  }"
                                />
                                <div
                                  v-if="form && $v.liquidItem.dcPer.$error"
                                  class="invalid-tooltip"
                                >
                                  <span v-if="!$v.liquidItem.dcPer.required">{{
                                    message
                                  }}</span>
                                </div>
                              </td>
                              <td class="" style="text-align: center">
                                <input
                                  disabled
                                  v-model="liquidItem.dcPrice"
                                  class="form-control text-end number"
                                />
                              </td>

                              <td class="" style="text-align: center">
                                <input
                                  disabled
                                  v-model="liquidItem.netPrice"
                                  class="form-control text-end number"
                                />
                              </td>
                              <td>
                                <ul class="list-inline mb-0 action">
                                  <li class="list-inline-item">
                                    <a
                                      class="px-2 text-danger"
                                      v-b-tooltip.hover
                                      title="Delete"
                                      @click="alertLiquid(liquidItem, index)"
                                    >
                                      <i
                                        class="uil uil-trash-alt font-size-18"
                                      ></i>
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" colspan="6" class="text-end">
                                มูลค่ารวม :
                              </th>
                              <td class="text-end">
                                {{
                                  Number(
                                    this.roLiquidTotalPrice
                                  ).toLocaleString()
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                ส่วนลดรวม :
                              </th>
                              <td class="border-0 text-end">
                                {{ this.roLiquidDcPer }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                ส่วนลดยอดรวม(บาท) :
                              </th>
                              <td class="border-0 text-end">
                                {{
                                  Number(this.roLiquidDcPrice).toLocaleString()
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                สุทธิรวม :
                              </th>
                              <td class="border-0 text-end">
                                {{
                                  Number(this.roLiquidNetPrice).toLocaleString()
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="row p-4">
                          <div class="col-md-6 ml-4">
                            <button
                              type="button"
                              class="btn btn-success btn-sm"
                              @click="addformLiquid()"
                            >
                              <i class="mdi mdi-plus me-1"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </b-tab>
                <!-- งานนอก -->
                <b-tab>
                  <template v-slot:title>
                    <span class="d-none d-sm-block">งานนอก</span>
                  </template>
                  <div class="p-4 border-top">
                    <form class="needs-validation" @submit.prevent="osArray">
                      <div class="row p-4">
                        <div class="col-md-6 col-sm-6">
                          <strong><code>* </code>ประเภทส่วนลด</strong>

                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="os.bath"
                              id="isWash"
                              name="outer-group[0][isWash]"
                              class="mb-3"
                              value="1"
                              unchecked-value="0"
                              @input="getRoOs()"
                              plain
                              >บาท</b-form-radio
                            >
                          </div>
                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="os.per"
                              id="isWash2"
                              name="outer-group[0][isWash]"
                              class="mb-3"
                              value="2"
                              unchecked-value="0"
                              @input="getRoOs()"
                              plain
                              >เปอร์เซ็น</b-form-radio
                            >
                          </div>
                        </div>
                      </div>
                      <span
                        >รายการงานนอก
                        <span class="badge bg-info font-size-12 ms-2"
                          >{{ this.rowRoOs.length }}
                        </span>
                        รายการ</span
                      >
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>ค่างานนอก</th>
                              <th style="text-align: center">ราคา/หน่วย</th>
                              <th>จำนวน</th>
                              <th style="text-align: center">มูลค่า</th>
                              <th style="text-align: center">ส่วนลด</th>
                              <th style="text-align: center">ส่วนลดยอด(บาท)</th>
                              <th style="text-align: center">สุทธิ</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(osItem, index) in rowRoOs" :key="index">
                              <td>{{ index + 1 }}</td>
                              <td scope="row">
                                <multiselect
                                  class="part"
                                  type="search"
                                  :options="rowOs"
                                  v-model="osItem.selectOs"
                                  :loading="loading"
                                  :close-on-select="true"
                                  :internal-search="true"
                                  open-direction="bottom"
                                  @search-change="getOs()"
                                  @input="upsertOs(osItem.selectOs, osItem)"
                                  :show-labels="false"
                                  label="nameTh"
                                  placeholder=""
                                  :class="{
                                    'is-invalid': form && $v.osItem.osId.$error,
                                  }"
                                >
                                  <span slot="noResult">ไม่พบข้อมูล</span>

                                  <div
                                    v-if="form && $v.osItem.osId.$error"
                                    class="invalid-tooltip"
                                  >
                                    <span v-if="!$v.osItem.osId.required">{{
                                      message
                                    }}</span>
                                  </div>
                                </multiselect>
                              </td>
                              <td class="">
                                <input
                                  min="0"
                                  v-model="osItem.pricePerOne"
                                  class="form-control text-end number"
                                  @input="totalOs(osItem)"
                                  @change="totalOs(osItem)"
                                  @keyup.enter="
                                    updateRoOs(
                                      osItem.osId,
                                      osItem.pricePerOne,
                                      osItem.amount,
                                      osItem.totalPrice,
                                      osItem.dcPer,
                                      osItem.dcPrice,
                                      osItem.netPrice,
                                      osItem
                                    )
                                  "
                                  :class="{
                                    'is-invalid':
                                      form && $v.osItem.pricePerOne.$error,
                                  }"
                                />
                                <div
                                  v-if="form && $v.osItem.pricePerOne.$error"
                                  class="invalid-tooltip"
                                >
                                  <span
                                    v-if="!$v.osItem.pricePerOne.required"
                                    >{{ message }}</span
                                  >
                                </div>
                              </td>
                              <td class="">
                                <input
                                  type="number"
                                  @keyup.enter="
                                    updateRoOs(
                                      osItem.osId,
                                      osItem.pricePerOne,
                                      osItem.amount,
                                      osItem.totalPrice,
                                      osItem.dcPer,
                                      osItem.dcPrice,
                                      osItem.netPrice,
                                      osItem
                                    )
                                  "
                                  v-model="osItem.amount"
                                  @input="totalOs(osItem)"
                                  @change="totalOs(osItem)"
                                  class="form-control text-end Dc"
                                  :class="{
                                    'is-invalid':
                                      form && $v.osItem.amount.$error,
                                  }"
                                />
                                <div
                                  v-if="form && $v.osItem.amount.$error"
                                  class="invalid-tooltip"
                                >
                                  <span v-if="!$v.osItem.amount.required">{{
                                    message
                                  }}</span>
                                </div>
                              </td>
                              <td class="" style="text-align: center">
                                <input
                                  disabled
                                  v-model="osItem.totalPrice"
                                  class="form-control text-end number"
                                />
                              </td>
                              <td class="">
                                <input
                                  v-model="osItem.dcPer"
                                  class="form-control text-end Dc"
                                  @keyup.enter="
                                    updateRoOs(
                                      osItem.osId,
                                      osItem.pricePerOne,
                                      osItem.amount,
                                      osItem.totalPrice,
                                      osItem.dcPer,
                                      osItem.dcPrice,
                                      osItem.netPrice,
                                      osItem
                                    )
                                  "
                                  @input="totalOs(osItem)"
                                  @change="totalOs(osItem)"
                                  append=".00"
                                  :class="{
                                    'is-invalid':
                                      form && $v.osItem.dcPer.$error,
                                  }"
                                />
                                <div
                                  v-if="form && $v.osItem.dcPer.$error"
                                  class="invalid-tooltip"
                                >
                                  <span v-if="!$v.osItem.dcPer.required">{{
                                    message
                                  }}</span>
                                </div>
                              </td>
                              <td class="" style="text-align: center">
                                <input
                                  disabled
                                  v-model="osItem.dcPrice"
                                  class="form-control text-end number"
                                />
                              </td>

                              <td class="" style="text-align: center">
                                <input
                                  disabled
                                  v-model="osItem.netPrice"
                                  class="form-control text-end number"
                                />
                              </td>
                              <td>
                                <ul class="list-inline mb-0 action">
                                  <li class="list-inline-item">
                                    <a
                                      class="px-2 text-danger"
                                      v-b-tooltip.hover
                                      title="Delete"
                                      @click="alertOs(osItem, index)"
                                    >
                                      <i
                                        class="uil uil-trash-alt font-size-18"
                                      ></i>
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" colspan="6" class="text-end">
                                มูลค่ารวม :
                              </th>
                              <td class="text-end">
                                {{
                                  Number(this.roOsTotalPrice).toLocaleString()
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                ส่วนลดรวม :
                              </th>
                              <td class="border-0 text-end">
                                {{ this.roOsDcPer }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                ส่วนลดยอดรวม(บาท) :
                              </th>
                              <td class="border-0 text-end">
                                {{ Number(this.roOsDcPrice).toLocaleString() }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                สุทธิรวม :
                              </th>
                              <td class="border-0 text-end">
                                {{ Number(this.roOsNetPrice).toLocaleString() }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="row p-4">
                          <div class="col-md-6 ml-4">
                            <button
                              type="button"
                              class="btn btn-success btn-sm"
                              @click="addformOs()"
                            >
                              <i class="mdi mdi-plus me-1"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </b-tab>
                <!-- อื่นๆ -->
                <b-tab>
                  <template v-slot:title>
                    <span class="d-none d-sm-block">อื่นๆ</span>
                  </template>
                  <div class="p-4 border-top">
                    <form class="needs-validation" @submit.prevent="extraArray">
                      <div class="row p-4">
                        <div class="col-md-6 col-sm-6">
                          <strong><code>* </code>ประเภทส่วนลด</strong>

                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="extra.bath"
                              id="isWash"
                              name="outer-group[0][isWash]"
                              class="mb-3"
                              value="1"
                              unchecked-value="0"
                              @input="getRoExtra()"
                              plain
                              >บาท</b-form-radio
                            >
                          </div>
                          <div
                            class="custom-radio form-check form-check-inline"
                          >
                            <b-form-radio
                              v-model="extra.per"
                              id="isWash2"
                              name="outer-group[0][isWash]"
                              class="mb-3"
                              value="2"
                              unchecked-value="0"
                              @input="getRoExtra()"
                              plain
                              >เปอร์เซ็น</b-form-radio
                            >
                          </div>
                        </div>
                      </div>
                      <span
                        >รายการอื่นๆ
                        <span class="badge bg-info font-size-12 ms-2"
                          >{{ this.rowRoExtra.length }}
                        </span>
                        รายการ</span
                      >
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>ค่าอื่นๆ</th>
                              <th style="text-align: center">ราคา/หน่วย</th>
                              <th>จำนวน</th>
                              <th style="text-align: center">มูลค่า</th>
                              <th style="text-align: center">ส่วนลด</th>
                              <th style="text-align: center">ส่วนลดยอด(บาท)</th>
                              <th style="text-align: center">สุทธิ</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(extraItem, index) in rowRoExtra"
                              :key="index"
                            >
                              <td>{{ index + 1 }}</td>
                              <td scope="row">
                                <multiselect
                                  class="part"
                                  type="search"
                                  :options="rowExtra"
                                  v-model="extraItem.selectExtra"
                                  :loading="loading"
                                  :close-on-select="true"
                                  :internal-search="true"
                                  open-direction="bottom"
                                  @search-change="getExtra()"
                                  @input="
                                    upsertExtra(
                                      extraItem.selectExtra,
                                      extraItem
                                    )
                                  "
                                  :show-labels="false"
                                  label="nameTh"
                                  placeholder=""
                                  :class="{
                                    'is-invalid':
                                      form && $v.extraItem.extraId.$error,
                                  }"
                                >
                                  <span slot="noResult">ไม่พบข้อมูล</span>

                                  <div
                                    v-if="form && $v.extraItem.extraId.$error"
                                    class="invalid-tooltip"
                                  >
                                    <span
                                      v-if="!$v.extraItem.extraId.required"
                                      >{{ message }}</span
                                    >
                                  </div>
                                </multiselect>
                              </td>
                              <td class="">
                                <input
                                  min="0"
                                  v-model="extraItem.pricePerOne"
                                  class="form-control text-end number"
                                  @input="totalExtra(extraItem)"
                                  @change="totalExtra(extraItem)"
                                  @keyup.enter="
                                    updateRoExtra(
                                      extraItem.extraId,
                                      extraItem.pricePerOne,
                                      extraItem.amount,
                                      extraItem.totalPrice,
                                      extraItem.dcPer,
                                      extraItem.dcPrice,
                                      extraItem.netPrice,
                                      extraItem
                                    )
                                  "
                                  :class="{
                                    'is-invalid':
                                      form && $v.extraItem.pricePerOne.$error,
                                  }"
                                />
                                <div
                                  v-if="form && $v.extraItem.pricePerOne.$error"
                                  class="invalid-tooltip"
                                >
                                  <span
                                    v-if="!$v.extraItem.pricePerOne.required"
                                    >{{ message }}</span
                                  >
                                </div>
                              </td>
                              <td class="">
                                <input
                                  type="number"
                                  @input="totalExtra(extraItem)"
                                  @change="totalExtra(extraItem)"
                                  @keyup.enter="
                                    updateRoExtra(
                                      extraItem.extraId,
                                      extraItem.pricePerOne,
                                      extraItem.amount,
                                      extraItem.totalPrice,
                                      extraItem.dcPer,
                                      extraItem.dcPrice,
                                      extraItem.netPrice,
                                      extraItem
                                    )
                                  "
                                  v-model="extraItem.amount"
                                  class="form-control text-end Dc"
                                  :class="{
                                    'is-invalid':
                                      form && $v.extraItem.amount.$error,
                                  }"
                                />
                                <div
                                  v-if="form && $v.extraItem.amount.$error"
                                  class="invalid-tooltip"
                                >
                                  <span v-if="!$v.extraItem.amount.required">{{
                                    message
                                  }}</span>
                                </div>
                              </td>
                              <td class="" style="text-align: center">
                                <input
                                  disabled
                                  v-model="extraItem.totalPrice"
                                  class="form-control text-end number"
                                />
                              </td>
                              <td class="">
                                <input
                                  min="0"
                                  v-model="extraItem.dcPer"
                                  class="form-control text-end Dc"
                                  @input="totalExtra(extraItem)"
                                  @change="totalExtra(extraItem)"
                                  @keyup.enter="
                                    updateRoExtra(
                                      extraItem.extraId,
                                      extraItem.pricePerOne,
                                      extraItem.amount,
                                      extraItem.totalPrice,
                                      extraItem.dcPer,
                                      extraItem.dcPrice,
                                      extraItem.netPrice,
                                      extraItem
                                    )
                                  "
                                  append=".00"
                                  :class="{
                                    'is-invalid':
                                      form && $v.extraItem.dcPer.$error,
                                  }"
                                />
                                <div
                                  v-if="form && $v.extraItem.dcPer.$error"
                                  class="invalid-tooltip"
                                >
                                  <span v-if="!$v.extraItem.dcPer.required">{{
                                    message
                                  }}</span>
                                </div>
                              </td>
                              <td class="" style="text-align: center">
                                <input
                                  disabled
                                  v-model="extraItem.dcPrice"
                                  class="form-control text-end number"
                                />
                              </td>

                              <td class="" style="text-align: center">
                                <input
                                  disabled
                                  v-model="extraItem.netPrice"
                                  class="form-control text-end number"
                                />
                              </td>
                              <td>
                                <ul class="list-inline mb-0 action">
                                  <li class="list-inline-item">
                                    <a
                                      class="px-2 text-danger"
                                      v-b-tooltip.hover
                                      title="Delete"
                                      @click="alertExtra(extraItem, index)"
                                    >
                                      <i
                                        class="uil uil-trash-alt font-size-18"
                                      ></i>
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" colspan="6" class="text-end">
                                มูลค่ารวม :
                              </th>
                              <td class="text-end">
                                {{
                                  Number(
                                    this.roExtraTotalPrice
                                  ).toLocaleString()
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                ส่วนลดรวม :
                              </th>
                              <td class="border-0 text-end">
                                {{ this.roExtraDcPer }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                ส่วนลดยอดรวม(บาท) :
                              </th>
                              <td class="border-0 text-end">
                                {{
                                  Number(this.roExtraDcPrice).toLocaleString()
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colspan="6"
                                class="border-0 text-end"
                              >
                                สุทธิรวม :
                              </th>
                              <td class="border-0 text-end">
                                {{
                                  Number(this.roExtraNetPrice).toLocaleString()
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="row p-4">
                          <div class="col-md-6 ml-4">
                            <button
                              type="button"
                              class="btn btn-success btn-sm"
                              @click="addformExtra()"
                            >
                              <i class="mdi mdi-plus me-1"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </b-tab>
              </b-tabs>
              <!-- สรุปยอดใบสั่งซ่อม -->
              <div class="card" v-if="this.$route.params.qnId">
                <a
                  href="javascript: void(0);"
                  class="text-dark"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="addproduct-billinginfo-collapse"
                  v-b-toggle.accordion-3
                >
                  <div class="p-4">
                    <div class="media align-items-center">
                      <!-- <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="
                          avatar-title
                          rounded-circle
                          bg-soft-primary
                          text-primary
                        "
                      >
                       
                      </div>
                    </div>
                  </div> -->
                      <div class="media-body overflow-hidden">
                        <h5 class="font-size-16 mb-1">สรุปยอดการสั่งซ่อม</h5>
                      </div>
                      <i
                        class="mdi mdi-chevron-up accor-down-icon font-size-24"
                      ></i>
                    </div>
                  </div>
                </a>

                <b-collapse
                  data-parent="#addproduct-accordion"
                  id="accordion-3"
                  visible
                  accordion="my-accordion"
                >
                  <div class="p-4 border-top">
                    <div class="container">
                      <div class="row">
                        <div class="col-7 col-md-4 col-sm-3 text-end">
                          <p>รวมทั้งหมด :</p>

                          <p>ส่วนลด(%) :</p>
                          <p>ส่วนลด(บาท) :</p>

                          <p>ค่าสุทธิ :</p>
                          <p>ภาษี (%) :</p>
                          <p>ภาษี (บาท) :</p>
                          <p>ค่าสุทธิรวม :</p>

                          <p>หัก ณ ที่จ่าย(%) :</p>
                          <p>หัก ณ ที่จ่าย(บาท) :</p>
                        </div>

                        <div class="col-5 col-md-2 col-sm-4">
                          <p>
                            <strong @change="calculateTotal"
                              >{{ this.roTotalPrice }}
                            </strong>
                          </p>
                          <div class="col-md-5 col-sm-5 mt-3">
                            <input
                              v-model="roDcPer"
                              class="form-control text-end"
                              @input="calculateTotal"
                              @change="calculateTotal"
                              @keyup.enter="
                                updateRoPrice(
                                  repairOrder.svId,
                                  repairOrder.roId
                                )
                              "
                              append=".00"
                            />
                          </div>
                          <p>
                            <strong>{{ this.roDcPrice }}</strong>
                          </p>
                          <p>
                            <strong>{{
                              Number(this.roNetPrice).toLocaleString()
                            }}</strong>
                          </p>
                          <p>
                            <strong>{{ this.roVatPer }}</strong>
                          </p>
                          <p>
                            <strong>{{
                              Number(this.roVatPrice).toLocaleString()
                            }}</strong>
                          </p>
                          <p>
                            <strong>{{
                              Number(this.roGrandTotalPrice).toLocaleString()
                            }}</strong>
                          </p>

                          <div class="col-md-5 col-sm-5">
                            <multiselect
                              class="text-end"
                              id="roWhtPer"
                              v-model="roWhtPer"
                              :options="optionsRoWhtPer"
                              track-by="name"
                              label="name"
                              :show-labels="false"
                              @change="calculateTotal"
                              @input="
                                updateRoPrice(
                                  repairOrder.svId,
                                  repairOrder.roId,
                                  roWhtPer
                                )
                              "
                            ></multiselect>
                          </div>
                          <p>
                            <strong>{{ this.roWhtPrice }}</strong>
                          </p>

                          <br />
                        </div>
                        <div class="col-md-6 col-sm-6 mb-3">
                          <div
                            class="row align-items-center m-0 mt-2 border-top"
                          >
                            <div class="col align-items-center mt-10">
                              <div
                                class="row bg-dark text-white m-0"
                                style="border-radius: 10px; margin: auto"
                              >
                                <div class="col-md-5">ยอดการชำระ</div>

                                <div class="col-md-5 font-size-24 text-end">
                                  <strong>{{
                                    Number(
                                      this.roPaymentAmount
                                    ).toLocaleString()
                                  }}</strong>
                                </div>
                                <div class="col-md-2 text-end">บาท</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12">
                            <button
                              class="btn btn-success float-end"
                              @click="
                                updateRoConfirm(
                                  repairOrder.svId,
                                  repairOrder.roId
                                )
                              "
                            >
                              บันทึกข้อมูลอีกครั้ง
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>

    <b-modal
      ref="modalCreateTaxInvoice"
      id="modalCreateTaxInvoice"
      title="ค้นหารายการใบกำกับภาษี/ใบเสร็จรับ"
      hide-footer
      centered
      size="xl"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  สาขา:
                  <multiselect
                    v-model="filterCreate.branch"
                    label="nameTh"
                    type="search"
                    :options="optionBranch"
                    :show-labels="false"
                    open-direction="bottom"
                    placeholder=""
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  เลขที่ใบแจ้งซ่อม:
                  <b-form-input
                    v-model="filterCreate.roCode"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  เลขใบแจ้งหนี้:
                  <b-form-input
                    v-model="filterCreate.licensePlate"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  ชื่อลูกค้า:
                  <b-form-input
                    v-model="filterCreate.vin"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  เลขที่ใบกำกับภาษี:
                  <b-form-input
                    v-model="filterCreate.licensePlate"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12">
            <b-button
              class="btn float-end"
              variant="info"
              type="submit"
              @click="handleSearchCreate"
            >
              <i class="uil-search-alt"></i>
              ค้นหา
            </b-button>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChangeCreate"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0">
            <b-table
              :items="rowsRo"
              :fields="fieldsCreate"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="onRowSelectedRo"
            >
              <template #cell(index)="rowsRo">
                {{ rowsRo.index + 1 }}
              </template>
            </b-table>
          </div>

          <div class="row">
            <div class="col">
              หน้า {{ currentPageCreate }} จาก {{ this.rowsRo.total }} ทั้งหมด
              {{ this.totalRecord }} รายการ
            </div>

            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPageCreate"
                    :total-rows="totalRecord"
                    :per-page="perPageCreate"
                    @change="handleChangePageCreate"
                  ></b-pagination>
                </ul>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
        </b-skeleton-wrapper>
      </div>
    </b-modal>
  </Layout>
</template>
